import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getJwtIdToken } from '../utils/auth';
import { Auth } from 'aws-amplify';
import { persistor } from '../redux/store';

const getHeaders = async (config: AxiosRequestConfig) => {
  const token = await getJwtIdToken();
  if (token === '') {
    return config.headers;
  }
  return {
    ...config.headers,
    authorization: `bearer ${token}`,
  };
};

const onFulfilled = async (config: AxiosRequestConfig) => {
  return {
    ...config,
    headers: (await getHeaders(config)) as any,
  };
};

const onError = (error: any) => {
  if (error.response.status === 401) {
    persistor.purge();
    Auth.signOut()
      .then(async (data) => {
        await persistor.purge();
        await persistor.flush();
        await persistor.pause();
        window.location.reload();
      })
      .catch((e) => {
        console.error(e);
      });
    //@ts-ignore
    window.location = '/login';
  }
  Promise.reject(error);
};

const onResponseFulfilled = (response: AxiosResponse) => {
  return response;
};

const authAxios = axios.create({
  baseURL: process.env.REACT_APP_AUTH_BASE_URL,
});
const integrationAxios = axios.create({
  baseURL: process.env.REACT_APP_INTEGRATIONS_BASE_URL,
});
const apiKeyAxios = axios.create({
  baseURL: process.env.REACT_APP_API_KEY_BASE_URL,
});
const webhookAxios = axios.create({
  baseURL: process.env.REACT_APP_WEBHOOK_BASE_URL,
});
const sandBoxAxios = axios.create({
  baseURL: process.env.REACT_APP_SANDBOX_BASE_URL,
});
const organizationAxios = axios.create({
  baseURL: process.env.REACT_APP_ORGANIZATION_BASE_URL,
});
const commonAxios = axios.create({
  baseURL: process.env.REACT_APP_COMMON_BASE_URL,
});
const userAxios = axios.create({
  baseURL: process.env.REACT_APP_USER_BASE_URL,
});
const userOnboardingAxios = axios.create({
  baseURL: process.env.REACT_APP_USER_ONBOARDING_BASE_URL,
});
const testFrameworkAxios = axios.create({
  baseURL: process.env.REACT_APP_TEST_FRAMEWORK_BASE_URL,
});
const ndaAxios = axios.create({
  baseURL: process.env.REACT_APP_NDA_BASE_URL,
});
const appStoreDetailsAxios = axios.create({
  baseURL: process.env.REACT_APP_APP_STORE_DETAILS_BASE_URL,
});
const adminPortalAxios = axios.create({
  baseURL: process.env.REACT_APP_ADMIN_PORTAL_BASE_URL,
});
const documentationAxios = axios.create({
  baseURL: process.env.REACT_APP_DOCUMENTATION_BASE_URL,
});

authAxios.interceptors.request.use(onFulfilled, onError);
integrationAxios.interceptors.request.use(onFulfilled, onError);
apiKeyAxios.interceptors.request.use(onFulfilled, onError);
webhookAxios.interceptors.request.use(onFulfilled, onError);
sandBoxAxios.interceptors.request.use(onFulfilled, onError);
organizationAxios.interceptors.request.use(onFulfilled, onError);
commonAxios.interceptors.request.use(onFulfilled, onError);
userAxios.interceptors.request.use(onFulfilled, onError);
userOnboardingAxios.interceptors.request.use(onFulfilled, onError);
testFrameworkAxios.interceptors.request.use(onFulfilled, onError);
ndaAxios.interceptors.request.use(onFulfilled, onError);
appStoreDetailsAxios.interceptors.request.use(onFulfilled, onError);
adminPortalAxios.interceptors.request.use(onFulfilled, onError);
documentationAxios.interceptors.request.use(onFulfilled, onError);

authAxios.interceptors.response.use(onResponseFulfilled, onError);
integrationAxios.interceptors.response.use(onResponseFulfilled, onError);
apiKeyAxios.interceptors.response.use(onResponseFulfilled, onError);
webhookAxios.interceptors.response.use(onResponseFulfilled, onError);
sandBoxAxios.interceptors.response.use(onResponseFulfilled, onError);
organizationAxios.interceptors.response.use(onResponseFulfilled, onError);
commonAxios.interceptors.response.use(onResponseFulfilled, onError);
userAxios.interceptors.response.use(onResponseFulfilled, onError);
userOnboardingAxios.interceptors.response.use(onResponseFulfilled, onError);
testFrameworkAxios.interceptors.response.use(onResponseFulfilled, onError);
ndaAxios.interceptors.response.use(onResponseFulfilled, onError);
appStoreDetailsAxios.interceptors.response.use(onResponseFulfilled, onError);
adminPortalAxios.interceptors.response.use(onResponseFulfilled, onError);
documentationAxios.interceptors.response.use(onResponseFulfilled, onError);

export {
  authAxios,
  integrationAxios,
  apiKeyAxios,
  webhookAxios,
  sandBoxAxios,
  organizationAxios,
  commonAxios,
  userAxios,
  userOnboardingAxios,
  testFrameworkAxios,
  ndaAxios,
  appStoreDetailsAxios,
  adminPortalAxios,
  documentationAxios,
};
