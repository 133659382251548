import {
  Box,
  Button,
  Divider,
  Flex,
  LoadingOverlay,
  Text,
  TextInput,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { object as validateObject, string as validateString } from 'yup';
import useIsProdWebhooksAdded from '../../../hooks/useIsProdWebhooksAdded';
import { IntegrationDefinition, IntegrationId, PartnerIntegrationStatus } from '../../../models';
import { IntegrationDetails } from '../../../models/appStoreDetails';
import { webhookActionCreator } from '../../../redux/actions';
import { RootState } from '../../../redux/common';
import { AuthenticationState, OrganizationState } from '../../../redux/reducers';
import { webhookService } from '../../../service';
import appStoreDetailsGoLiveService from '../../../service/appStoreDetailsGoLive.service';
import AppStoreConfirmationDialog from '../AppStoreDetailsConfirmationModal/AppStoreConfirmationDialog';
import { appDetailsCardStyles } from './style';
import ndaService from '../../../service/nda.service';
import { NdaStatus } from '../../../models/nda';
import integratioService from '../../../service/integration.service';
import integrationGoLiveService from '../../../service/integrationGoLive.service';

type AppDetailsCardProps = {
  integrationDetails: IntegrationDetails | undefined;
  integrationDefinitions: IntegrationDefinition[] | undefined;
  activatedIntegration: PartnerIntegrationStatus;
  isIntegrationReadyToGoLive: boolean;
  refetchGetAppStoreDetails: Function;
  refetchGetReadyToGoLiveIntegrations: Function;
  refetchGetIntegrationsWithGoLiveStatuses: Function;
  disabledAllFields: boolean;
};

const AppDetailsCard: FC<AppDetailsCardProps> = ({
  integrationDetails,
  integrationDefinitions,
  activatedIntegration,
  isIntegrationReadyToGoLive,
  refetchGetAppStoreDetails,
  refetchGetReadyToGoLiveIntegrations,
  refetchGetIntegrationsWithGoLiveStatuses,
  disabledAllFields,
}) => {
  const [isAllWebhooksAddedProd, isLoadingWebhookDefinitions, isLoadingWebhookValues] =
    useIsProdWebhooksAdded(integrationDetails?.integration.id);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const dispatch = useDispatch();

  const { userData } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });

  const { organization } = useSelector<RootState, OrganizationState>((state: RootState) => {
    return state.organization;
  });

  const { data: integrationSummaries, isLoading: isLoadingActivatedIntegrations } = useQuery({
    queryKey: ['get-integration-summaries', userData.organizationId],
    queryFn: () => integratioService.getIntegrationParameterSummary(userData.organizationId),
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const { data: prodParameters, isLoading: isLoadingProdParameters, refetch: refetchProdParameters } = useQuery({
    queryKey: ['get-integration-parameters-prod', userData.organizationId],
    queryFn: () => integrationGoLiveService.getIntegrationParameters(userData.organizationId, integrationDetails?.integration.id?.toUpperCase()),
    onError: (error: any) => {
        notifications.show({
            title: error.name ?? 'Something went wrong',
            message: error.message ?? 'Something went wrong',
            autoClose: 2000,
            color: 'red',
        });
    },
});

  const { values, setFieldValue, isValid, handleBlur, touched, errors } = useFormik({
    initialValues: {
      applicationName: integrationDetails?.name || '',
    },
    onSubmit: () => {},
    validationSchema: validateObject().shape({
      applicationName: validateString().trim().required('Application name required'),
    }),
    validateOnMount: true,
  });

  useEffect(() => {
    setFieldValue(
      'applicationName',
      values.applicationName ? values.applicationName : integrationDetails?.name,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationDetails]);

  const { data: webhookDefinitions, isLoading: isLoadingWebhookDefinitionsFetch } = useQuery({
    queryKey: [
      `get-webHook-definitions-${activatedIntegration.integration.id}`,
      activatedIntegration.integration.id,
    ],
    queryFn: () => webhookService.getWebHookDefinitions(activatedIntegration.integration.id),
    onSuccess: (data) => {
      dispatch(webhookActionCreator.changeWebhookDefinitions(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const { data: ndaStatus, isLoading: isLoadingNdaStatus } = useQuery({
    queryKey: ['get-nda-status', userData.organizationId],
    queryFn: () => ndaService.getNdaStatus(userData.organizationId),
    onSuccess: () => {},
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const uploadApplicationImage = useMutation({
    mutationFn: (payload: { organizationId: string; file: File; integrationId: string }) => {
      return appStoreDetailsGoLiveService.uploadApplicationImage(
        payload.organizationId,
        payload.file,
        payload.integrationId,
      );
    },
    onSuccess: (data) => {
      refetchGetAppStoreDetails();
      refetchGetReadyToGoLiveIntegrations();
      refetchProdParameters();
      notifications.show({
        title: 'Successfully Uploaded',
        message: 'Image is successfully uploaded',
        color: 'green',
      });
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const requestToGoLive = useMutation({
    mutationFn: (payload: { organizationId: string; integrationId: string }) => {
      return appStoreDetailsGoLiveService.requestToGoLive({
        organizationId: payload.organizationId,
        integrationId: payload.integrationId,
      });
    },
    onSuccess: (data) => {
      refetchGetAppStoreDetails();
      refetchGetReadyToGoLiveIntegrations();
      refetchProdParameters();
      refetchGetIntegrationsWithGoLiveStatuses();
      setIsModalOpened(false);
      notifications.show({
        title: 'Successfully Submitted',
        message: 'Request is successfully submitted',
        color: 'green',
      });
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const saveName = useMutation({
    mutationFn: (payload: {
      organizationId: string;
      applicationName: string;
      integrationId: IntegrationId;
    }) => {
      return appStoreDetailsGoLiveService.saveName({
        organizationId: payload.organizationId,
        integration: payload.integrationId,
        applicationName: payload.applicationName,
      });
    },
    onSuccess: (data) => {
      refetchGetAppStoreDetails();
      refetchGetReadyToGoLiveIntegrations();
      refetchProdParameters();
      notifications.show({
        title: 'Successfully Saved',
        message: 'Application name is successfully saved',
        color: 'green',
      });
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const getIntegrationDefinitionName = () => {
    return integrationDefinitions?.find(
      (integrationDefinition) =>
        integrationDefinition?.id?.id === activatedIntegration.integration.id,
    )?.name;
  };

  const theme = useMantineTheme();

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      uploadApplicationImage.mutate({
        organizationId: userData.organizationId,
        file: file,
        integrationId: activatedIntegration.integration.id,
      });
    }
  };

  const openFilePicker = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const isDefinitionSettingsAddedProd = () => {
    if(!organization.enableParameterDefinitions) {
      return true;
    }

    const integrationSummary = integrationSummaries?.find(integrationSummary => integrationSummary.integration.id === integrationDetails?.integration.id);
    if(integrationSummary?.isDisabled) {
      return true;
    }

    return prodParameters;
  }

  const isNDASubmitted = useCallback(() => {
    if (ndaStatus === NdaStatus.SUBMITTED || ndaStatus === NdaStatus.APPROVED) return true;
    return false;
  }, [ndaStatus]);

  const isDisabledRequestGoLive = () => {
    return (
      !isNDASubmitted() ||
      !isIntegrationReadyToGoLive ||
      disabledAllFields ||
      !isAllWebhooksAddedProd ||
      !isDefinitionSettingsAddedProd()
    );
  };

  const getRequestToGoLiveMessage = () => {
    const webhookDefinitionInfo = webhookDefinitions?.find(
      (webhookDefinition) =>
        webhookDefinition?.integration?.id === activatedIntegration?.integration?.id,
    );
    if (webhookDefinitionInfo && !webhookDefinitionInfo.productionRequired) {
      return 'All app details must be filled';
    }
    return 'All production Webhooks and app details must be filled';
  };

  return (
    <>
      <LoadingOverlay visible={isLoadingNdaStatus} />
      <Box sx={appDetailsCardStyles(theme)}>
        <AppStoreConfirmationDialog
          opened={isModalOpened}
          close={() => setIsModalOpened(false)}
          handleConfirm={() =>
            requestToGoLive.mutate({
              organizationId: userData.organizationId,
              integrationId: activatedIntegration.integration.id,
            })
          }
        />
        <LoadingOverlay
          visible={
            isLoadingWebhookDefinitions ||
            isLoadingWebhookValues ||
            isLoadingWebhookDefinitionsFetch || isLoadingActivatedIntegrations || isLoadingProdParameters
          }
        />
        <Flex className="app-details-card-container">
          <Flex className="app-details-content-container" direction={'row'}>
            <Flex className="name-container">
              <Text className="main-title">{getIntegrationDefinitionName()}</Text>
            </Flex>
            <Divider color={theme.colors.gray[2]} orientation="vertical" />
            <Flex direction={'column'} className="form-container">
              <Text className="app-details-text">Application Details for Publishing:</Text>
              <Flex className="app-name-container" align={'flex-end'} justify={'space-between'}>
                <TextInput
                  placeholder="Application Name"
                  value={values.applicationName}
                  label="Application Name"
                  className="name-input"
                  name={'applicationName'}
                  onChange={(e) => {
                    setFieldValue('applicationName', e.target.value.trimStart());
                  }}
                  onBlur={(e) => {
                    setFieldValue('applicationName', e.target.value.trimEnd());
                    handleBlur(e);
                  }}
                  withAsterisk
                  error={touched?.applicationName && errors?.applicationName}
                  disabled={disabledAllFields}
                />
                <Button
                  disabled={!isValid || disabledAllFields}
                  onClick={() => {
                    saveName.mutate({
                      organizationId: userData.organizationId,
                      integrationId: activatedIntegration.integration,
                      applicationName: values.applicationName,
                    });
                  }}
                >
                  Save
                </Button>
              </Flex>
              <Text className="image-text">Application Image</Text>
              <Flex className="upload-image-container" align={'center'}>
                {integrationDetails?.imageUrl ? (
                  <img
                    src={integrationDetails?.imageUrl}
                    alt="applicationImage"
                    width={200}
                    height={150}
                  />
                ) : (
                  <div className="no-image-div" />
                )}

                <input
                  type="file"
                  style={{ display: 'none' }}
                  accept="image/png, image/gif, image/jpeg"
                  onChange={handleFileChange}
                  ref={fileInputRef}
                  disabled={disabledAllFields}
                />
                <Button
                  className="upload-image-button"
                  onClick={openFilePicker}
                  disabled={disabledAllFields}
                >
                  Upload Image
                </Button>
              </Flex>
            </Flex>
          </Flex>
          <Flex className="button-container">
            <Tooltip
              label={
                !isNDASubmitted()
                  ? 'The NDA must be submitted.'
                  : isDisabledRequestGoLive() && !disabledAllFields
                  ? getRequestToGoLiveMessage()
                  : ''
              }
              disabled={!(isDisabledRequestGoLive() && !disabledAllFields)}
              color="gray"
            >
              <div>
                <Button
                  disabled={isDisabledRequestGoLive()}
                  onClick={() => {
                    setIsModalOpened(true);
                  }}
                >
                  Request to go-live
                </Button>
              </div>
            </Tooltip>
          </Flex>
        </Flex>
      </Box>
    </>
  );
};

export default AppDetailsCard;
