import { apiKeyAxios } from '../config/axios.config';
import { ApiKey, ApiKeyGenerationRequest, ApiSuiteDefinition } from '../models/apiKey';

const apikeyService = {
  getApiKeysbyIntegrationAndOrganizationId: async (
    integrationId: string,
    organizationId: string,
  ): Promise<ApiKey[]> => {
    const { data } = await apiKeyAxios.get(`/keys/${integrationId}/${organizationId}`);
    return data;
  },
  generateApiKey: async (payload: ApiKeyGenerationRequest): Promise<ApiKey> => {
    const { data } = await apiKeyAxios.post(`/keys`, payload);
    return data;
  },
  getApiSuites: async (integrationId: string): Promise<ApiSuiteDefinition[]> => {
    const { data } = await apiKeyAxios.get(`/suites/${integrationId}`);
    return data;
  },
  checkIsProductionEnabledByIntegration: async (
    integrationId: string,
    organizationId: string,
  ): Promise<boolean> => {
    const { data } = await apiKeyAxios.get(
      `/is-production-enabled-by-integration/${integrationId}/${organizationId}`,
    );
    return data;
  },
  checkIsProductionEnabled: async (organizationId: string): Promise<Boolean> => {
    const { data } = await apiKeyAxios.get(`/is-production-enabled/${organizationId}`);
    return data;
  },
};

export default apikeyService;
