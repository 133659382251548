import { Box, Image, LoadingOverlay, Navbar, Text, useMantineTheme } from '@mantine/core';
import {
  IconBriefcase,
  IconBuildingStore,
  IconExternalLink,
  IconKey,
  IconLogout2,
  // IconMessageChatbot,
  IconNotebook,
  IconPlug,
  IconTestPipe,
  IconUsers,
  IconWebhook,
  IconCloudUpload,
} from '@tabler/icons-react';
import { Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import GTLOGO from '../../assets/images/gt-logo.png';
import useIsApiKeyGeneratedForProd from '../../hooks/useIsApiKeyGeneratedForProd';
import useIsItegrationApprovedForGoLive from '../../hooks/useIsItegrationApprovedForGoLive';
import { IntegrationActivationStatus } from '../../models';
import { authenticationActionCreator, testFrameworkActionCreator } from '../../redux/actions';
import { RootState } from '../../redux/common';
import {
  AuthenticationState,
  IntegrationState,
  OrganizationState,
  TestFrameworkState,
  WebhookState,
} from '../../redux/reducers';
import { persistor } from '../../redux/store';
import SideBarLink from './SideBarLink';
import { sidebarStyles } from './styles';
import { notifications } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';
import documentationService from '../../service/documentation.service';

const SideBar: React.FC = () => {
  const theme = useMantineTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isItegrationApprovedForGoLive = useIsItegrationApprovedForGoLive();
  const isApiKeyGeneratedForProduction = useIsApiKeyGeneratedForProd();

  const { userData } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });

  const { testSuiteDefinitions } = useSelector<RootState, TestFrameworkState>(
    (state: RootState) => {
      return state.testFramework;
    },
  );

  const { organization } = useSelector<RootState, OrganizationState>((state: RootState) => {
    return state.organization;
  });

  const { activatedIntegrations } = useSelector<RootState, IntegrationState>((state: RootState) => {
    return state.integration;
  });

  const { webhookDefinitions } = useSelector<RootState, WebhookState>((state: RootState) => {
    return state.webhook;
  });

  const documentationAuthRequest = useMutation({
    mutationFn: documentationService.getDocumentationAuth,
    onSuccess: (data) => {
      if (!!data && data?.authToken) {
        window.open(`https://docs.grubtech.io?auth_token=${data.authToken}`);
        return;
      }
    },
    onError: (error: any) => {
      notifications.show({
        title: 'Something went wrong',
        message: JSON.stringify(error) ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const isThereAnyIntegrationTestCasesApprovedByAdmin = activatedIntegrations.some(
    (activatedIntegration) =>
      activatedIntegration.status.includes(
        IntegrationActivationStatus.TEST_CASES_APPROVED_BY_ADMIN,
      ),
  );

  const isThereAnyIntegrationTestCasesApprovedByAdminNeededProductionWebhook = activatedIntegrations
    .filter((activatedIntegration) => {
      const integrationIdOfProdRequiredWebhookDefinitions = webhookDefinitions
        .filter((webhookDefinition) => webhookDefinition.productionRequired)
        .map((webhookDefinition) => webhookDefinition.integration.id);
      return integrationIdOfProdRequiredWebhookDefinitions.includes(
        activatedIntegration.integration.id,
      );
    })
    .some((activatedIntegration) =>
      activatedIntegration.status.includes(
        IntegrationActivationStatus.TEST_CASES_APPROVED_BY_ADMIN,
      ),
    );

  const mainLinks = [
    // { link: '/dashboard', label: 'Dashboard', icon: IconLayoutDashboard },
    { link: '/organisation', label: 'Organisation', icon: IconUsers },
    { link: '/manage-api-access', label: 'Manage API Access', icon: IconPlug },
  ];

  const configureLinks = [
    {
      link: '/credentials',
      label: 'API Credentials',
      icon: IconKey,
      isWarning: isItegrationApprovedForGoLive && !isApiKeyGeneratedForProduction,
      warningText: 'Generate your production API credentials',
    },
    {
      link: '/webhooks',
      label: 'Webhooks',
      icon: IconWebhook,
      isWarning:
        isThereAnyIntegrationTestCasesApprovedByAdminNeededProductionWebhook &&
        !isItegrationApprovedForGoLive,
      warningText: 'Configure your production Webhooks',
    },
    { link: '/sandbox-sites', label: 'Sandbox', icon: IconBuildingStore },
    ...(organization.enableParameterDefinitions ? [{
      link: '/definition-settings',
      label: 'Definition Settings',
      icon: IconCloudUpload,
      isWarning: isThereAnyIntegrationTestCasesApprovedByAdmin && !isItegrationApprovedForGoLive,
      warningText: 'Configure definition settings',
    }] : []),
    {
      link: '/app-store-details',
      label: 'App Store Details',
      icon: IconBriefcase,
      disabled: !isThereAnyIntegrationTestCasesApprovedByAdmin,
      disabledText: 'UAT should be approved by administrator',
      isWarning: isThereAnyIntegrationTestCasesApprovedByAdmin && !isItegrationApprovedForGoLive,
      warningText: 'Enter your app details',
    },
  ];

  const uatLinks = testSuiteDefinitions?.map(({ id, name }) => {
    return {
      link: `/uat/${id}`,
      label: name,
      icon: IconTestPipe,
      id: id,
    };
  });

  const supportLinks = [
    {
      link: 'https://docs.grubtech.io/',
      label: 'Documentation',
      icon: IconNotebook,
      externalIcon: IconExternalLink,
    },
    // { link: '#', label: 'Contact us', icon: IconMessageChatbot, externalIcon: IconExternalLink },
  ];

  const mainArea = mainLinks.map((item) => (
    <SideBarLink
      to={item.link}
      item={item}
      key={item.label}
      disabled={
        !userData ||
        userData.organizationId === null ||
        (!userData.hasActiveApi && item.link !== '/manage-api-access')
      }
    />
  ));
  const configureArea = configureLinks.map((item) => (
    <SideBarLink
      to={item.link}
      item={item}
      key={item.label}
      disabled={
        item.disabled !== undefined
          ? item.disabled
          : !userData || userData.organizationId === null || !userData.hasActiveApi
      }
      disabledText={item.disabledText}
      isWarning={item.isWarning}
      warningText={item.warningText}
    />
  ));
  const uatArea = uatLinks.map((item) => (
    <SideBarLink
      to={item.link}
      item={item}
      key={item.label}
      disabled={!userData || userData.organizationId === null || !userData.hasActiveApi}
      onClick={() => {
        dispatch(testFrameworkActionCreator.changeSelectedTestSuite(item.id));
        dispatch(testFrameworkActionCreator.changeSelectedFlow(null));
        dispatch(testFrameworkActionCreator.changeSelectedSubFlow(null));
        dispatch(testFrameworkActionCreator.changeselectedTestCase(null));
        dispatch(testFrameworkActionCreator.changeTestCaseExecutionData(null));
        dispatch(testFrameworkActionCreator.changeTestSuiteIntanceData(null));
      }}
    />
  ));

  const supportArea = supportLinks.map((item) => (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      className="sidebar-external-link"
      data-cy={`${item.label}-link`}
      onClick={() => {
        documentationAuthRequest.mutate(userData.email);
      }}
      target="_blank"
      rel="noreferrer"
      key={item.label}
    >
      <Box className="sidebar-external-link-box">
        <item.icon className="sidebar-external-link-icon" stroke={2} size={20} />
        <span>{item.label}</span>
      </Box>
      <item.externalIcon className="sidebar-external-link-icon" stroke={2} size={20} />
    </a>
  ));

  const onClickVerify = async () => {
    await signOut();
  };

  const signOut = async () => {
    try {
      Auth.signOut()
        .then(async (data) => {
          await persistor.purge();
          await persistor.flush();
          await persistor.pause();
          window.location.reload();
          redirectToLoginPage();
        })
        .catch((e) => {
          console.error(e);
          redirectToLoginPage();
        });
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  const redirectToLoginPage = () => {
    dispatch(
      authenticationActionCreator.changeAuthData({
        isAuthenticated: false,
        userData: null,
      }),
    );
    navigate(`/`);
  };

  return (
    <Box sx={sidebarStyles(theme)}>
      <LoadingOverlay id={'side-bar-loading'} visible={documentationAuthRequest.isLoading} />
      <Navbar height={'100vh'} width={{ sm: 240 }} p="md" c={'gray'}>
        <Navbar.Section>
          <Box className="sidebar-header">
            <Image src={GTLOGO} width={150} height={45} />
            <Text className="sidebar-header-title">Developer Portal</Text>
          </Box>
        </Navbar.Section>

        <Navbar.Section>{mainArea}</Navbar.Section>
        <Navbar.Section>
          <Text className="sidebar-section-title">Configure</Text>
          {configureArea}
        </Navbar.Section>
        {testSuiteDefinitions && testSuiteDefinitions.length > 0 && (
          <Navbar.Section>
            <Text className="sidebar-section-title">UAT</Text>
            {uatArea}
          </Navbar.Section>
        )}
        <Navbar.Section grow>
          <Text className="sidebar-section-title">Support</Text>
          {supportArea}
        </Navbar.Section>

        <Navbar.Section className="sidebar-footer">
          <IconLogout2 className="sidebar-footer-icon" onClick={onClickVerify} />
          <Text>{userData?.name}</Text>
        </Navbar.Section>
      </Navbar>
    </Box>
  );
};

export default SideBar;
