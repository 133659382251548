import { notifications } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/common';
import { AuthenticationState, IntegrationState } from '../redux/reducers';
import { apikeyGoLiveService } from '../service';

const useIsApiKeyGeneratedForProd = () => {
  const [isApiKeyGeneratedForProduction, setIsApiKeyGeneratedForProduction] = useState(false);

  const { activatedIntegrations } = useSelector<RootState, IntegrationState>((state: RootState) => {
    return state.integration;
  });

  const { userData } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });

  useEffect(() => {
    if (activatedIntegrations && activatedIntegrations.length > 0) {
      activatedIntegrations.forEach((integration) => {
        getTestSuitesByIntegration.mutate(integration.integration.id);
      });
    }
    // eslint-disable-next-line
  }, [activatedIntegrations]);

  const getTestSuitesByIntegration = useMutation({
    mutationFn: (integrationId: string) => {
      return apikeyGoLiveService.getApiKeysbyIntegrationAndOrganizationId(
        integrationId,
        userData.organizationId,
      );
    },
    onSuccess: (data) => {
      if (!isApiKeyGeneratedForProduction) {
        if (data && data.length > 0) {
          return setIsApiKeyGeneratedForProduction(true);
        }
        setIsApiKeyGeneratedForProduction(false);
      }
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  return isApiKeyGeneratedForProduction;
};

export default useIsApiKeyGeneratedForProd;
