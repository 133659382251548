import { UserModle } from './auth';
import { Coordinates } from './coordinates';
import { IntegrationId } from './integration';
import { Address, ContactInfo } from './user';

export type SandBoxSite = {
  id: string;
  name: string;
  locationId: string;
  fullfilledBy: string;
  isNew: boolean;
  isActivating: boolean;
};

export enum UserFederationType {
  GRUBTECH,
  GOOGLE,
  FACEBOOK,
}

export type User = {
  name: string;
  email: string;
  organizationId: string;
  userFederationType: UserFederationType;
};

export type GenericObject<T> = {
  [key: string]: T;
};

export type AddressBuilderField = {
  label: string;
  name: string;
  value: string;
  optional?: boolean;
  requiredMsg?: string;
};

export type AddressFieldWithCountryAddressId = {
  addressFieldWithOptionalInfoList: AddressFieldWithOptionalInfo[];
  countryAddressFormatId: string;
};

export type AddressFieldWithOptionalInfo = {
  addressField: AddressField;
  isOptional: boolean;
  countryId: string;
};

export type AddressField = {
  id: string;
  code: string;
  displayName: string;
};

export type Country = {
  id: string;
  name: string;
  code: string;
  currencyId: string;
  phoneNumberPrefix: string;
  applicableTaxes: [
    {
      taxId: string;
      value: 0;
    },
  ];
  alpha2Code: string;
  timeZone: string;
};

export enum SandboxStatus {
  CREATING = 'CREATING',
  NOT_CREATED = 'NOT_CREATED',
  CREATED = 'CREATED',
  FAILED = 'FAILED',
  FAILED_RETRIES_EXHAUSTED = 'FAILED_RETRIES_EXHAUSTED',
  FAILED_AND_DELETED = 'FAILED_AND_DELETED',
  DELETED = 'DELETED',
}

export type SandboxCreationFormData = {
  sandboxMenuDataRows: SandboxMenuDataRow[];
  sandboxCommonData: SandboxCommonData[];
  sandboxPropertyDefinitions: SandboxPropertyDefinition[];
  organizationId: string;
};

export type SandboxCommonData = {
  id: SandboxPropertyId;
  name: string;
  description: string;
  isMandatory: boolean;
};

export interface SandboxPropertyDefinition {
  id: SandboxPropertyId;
  name: string;
  description: string;
  mandatory: boolean;
  integration: IntegrationId;
  readOnly: boolean;
}

export type SandboxCreationData = {
  sandboxMenuDataRows: SandboxMenuDataRow[];
  sandboxPropertyValues: SandboxPropertyValue[];
  organizationId: string;
  email: string;
};

export type SandboxPropertyValue = {
  integration: IntegrationId;
  propertyId: SandboxPropertyId;
  value: string;
};

export type SandboxPropertyId = {
  id: string;
};

export type Organization = {
  name: string;
  countryId: string;
  contactInfo: ContactInfo;
  companyWebsite: string;
  agreedTermId: string;
  address: Address;
  invitationCode: string;
  cityId: string;
  id: string;
  locationCoordinates: Coordinates;
  enableParameterDefinitions: boolean;
};

export type SandboxMenuDataRow = {
  itemId: string;
  name: string;
  type: SandboxMenuDataType;
  price: string;
};

export enum SandboxMenuDataType {
  MENU = 'MENU',
  MENU_ITEM = 'MENU_ITEM',
  MODIFIER = 'MODIFIER',
}

export type SandboxSummaryDto = {
  id: string;
  name: string;
  isLatest: boolean;
  overallStatus: SandboxStatus;
  commonSandboxStepsAndStatus: SandboxStepsWithStatusDto;
  integrationSandboxStepsAndStatus: SandboxStepsWithStatusDto[];
  sandboxDetails: CreatedSandboxDetails[];
  sandboxPropertyValueValidations: SandboxPropertyValueValidation[];
};

export type CreatedSandboxDetails = {
  name: string;
  description: string;
  value: string;
};

export type SandboxStepsWithStatusDto = {
  id: string;
  stepsWithStatus: SandboxStepWithStatusDto[];
  sandboxStatus: SandboxStatus;
};

export type SandboxStepWithStatusDto = {
  step: SandboxStepDefinition;
  status: SandboxStatus;
};

export type SandboxStepDefinition = {
  id: string;
  name: string;
  description: string;
};

export type CreateSandboxApiData = {
  partnerId: string;
  creationData: SandboxCreationData;
};

export type SandboxPropertyValueValidation = {
  integration: IntegrationId;
  propertyId: SandboxPropertyId;
  isValid: boolean;
  validationErrorMessage: string;
};

export type UserEmployee = {
  user: UserModle;
  employee: Employee;
};

export type Employee = {};

export interface SandboxPropertyDefinitionValue extends SandboxPropertyDefinition {
  value: string;
  displayType: SandboxPropertyDefinitionDisplayType;
  availableValues: Map<string, string>;
  displayLabel: string;
}

export enum SandboxPropertyDefinitionDisplayType {
  INPUT = 'INPUT',
  SELECT = 'SELECT',
}
