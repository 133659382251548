import { Sx, MantineTheme } from '@mantine/core';

export const definitionSettingsStyles = (theme: MantineTheme): Sx => {
  return {
    '.content': {
        background: '#fff',
        padding: theme.spacing.xl,
      },
      '.main-container': {
        width: '100%',
        marginTop: '12px',
      },
      '.card-content': {
        height: '100%',
      },
  };
};
