import { FC } from 'react';
import { Box, Flex, LoadingOverlay, Tabs, useMantineTheme, Text } from '@mantine/core';
import { appStoreDetailsStyles } from './style';
import PageLayout from '../../components/PageLayout/PageLayout';
import PageHeader from '../../components/PageHeader/PageHeader';
import AppDetailsCard from './AppDetailsCard/AppDetailsCard';
import { useQuery } from '@tanstack/react-query';
import { AuthenticationState } from '../../redux/reducers';
import { RootState } from '../../redux/common';
import { useDispatch, useSelector } from 'react-redux';
import appStoreDetailsGoLiveService from '../../service/appStoreDetailsGoLive.service';
import { appStoreDetailsActionCreator } from '../../redux/actions/appStoreDetails';
import { notifications } from '@mantine/notifications';
import integratioService from '../../service/integration.service';
import { integrationActionCreator } from '../../redux/actions';
import { PartnerIntegrationStatus } from '../../models';

const AppStoreDetails: FC = () => {
  const theme = useMantineTheme();
  const dispatch = useDispatch();

  const { userData } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });

  const {
    data: readyToGoLiveIntegrations,
    isLoading: isLoadingReadyToGoLiveIntegrations,
    refetch: refetchGetReadyToGoLiveIntegrations,
  } = useQuery({
    queryKey: ['get-ready-to-go-live-integrations', userData.organizationId],
    queryFn: () =>
      appStoreDetailsGoLiveService.getReadyToGoLiveIntegrations(userData.organizationId),
    onSuccess: (data) => {
      dispatch(appStoreDetailsActionCreator.changeGetReadyToGoLiveIntegrations(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const {
    data: integrationsWithStatuses,
    isLoading: isLoadingIntegrationWithStatuses,
    refetch: refetchGetIntegrationsWithGoLiveStatuses,
  } = useQuery({
    queryKey: ['get-integration-with-go-live-status', userData.organizationId],
    queryFn: () =>
      appStoreDetailsGoLiveService.getIntegrationsWithGoLiveStatuses(userData.organizationId),
    onSuccess: (data) => {
      dispatch(appStoreDetailsActionCreator.changeGetIntegrationsWithGoLiveStatuses(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const {
    data: appStoreDetails,
    isLoading: isLoadingAppStoreDetails,
    refetch: refetchGetAppStoreDetails,
  } = useQuery({
    queryKey: ['get-app-store-details', userData.organizationId],
    queryFn: () => appStoreDetailsGoLiveService.getAppStoreDetails(userData.organizationId),
    onSuccess: (data) => {
      dispatch(appStoreDetailsActionCreator.changeAppStoreDetails(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const { data: integrationDefinitions, isLoading: isLoadingIntegrationDefinitions } = useQuery({
    queryKey: ['get-integration-definitions-app-store-details', userData?.organizationId],
    queryFn: () => integratioService.getIntegrations(userData.organizationId),
    onSuccess: (data) => {
      dispatch(integrationActionCreator.changeIntegrationDefinition(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const { data: activatedIntegrations, isLoading: isLoadingActivatedIntegrations } = useQuery({
    queryKey: ['get-activated-integration-app-store-details', userData.organizationId],
    queryFn: () => integratioService.getActivatedIntegrations(userData.organizationId),
    onSuccess: (data) => {
      dispatch(integrationActionCreator.changeActivatedIntegration(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const getIntegrationDetailsOfActivatedIntegration = (
    activatedIntegration: PartnerIntegrationStatus,
  ) => {
    return appStoreDetails?.integrationDetailsList?.find(
      (integrationDetail) =>
        integrationDetail.integration.id === activatedIntegration.integration.id,
    );
  };

  const isRequestAllreadySent = (activatedIntegration: PartnerIntegrationStatus) => {
    const activatedIntegrationWithStatus = integrationsWithStatuses?.find(
      (integrationWithStatus) =>
        integrationWithStatus?.integration?.id === activatedIntegration?.integration?.id,
    );
    if (!activatedIntegrationWithStatus) {
      return false;
    }

    return activatedIntegrationWithStatus.requestAlreadySent;
  };

  const isIntegrationReadyToGoLive = (activatedIntegration: PartnerIntegrationStatus) => {
    return (
      readyToGoLiveIntegrations?.some(
        (readyToGoLiveIntegration) =>
          readyToGoLiveIntegration.id === activatedIntegration.integration.id,
      ) || false
    );
  };
  return (
    <>
      <PageLayout
        id={'app-store-details'}
        sx={appStoreDetailsStyles(theme)}
        headerSection={
          <PageHeader
            id={'app-store-details-header'}
            title={'App Store Details'}
            description="Manage all your application details here. These details will be used when the application is published to the Grubtech app store for our customers/partners."
          />
        }
      >
        <LoadingOverlay
          visible={
            isLoadingAppStoreDetails ||
            isLoadingIntegrationDefinitions ||
            isLoadingActivatedIntegrations ||
            isLoadingReadyToGoLiveIntegrations ||
            isLoadingIntegrationWithStatuses
          }
        />

        <Box>
          <Flex direction={'column'} className="app-store-details-container">
            <Flex direction={'row'} className="tab-container">
              <Tabs className="tabs" color="teal" defaultValue="urls" value={'sandbox'}>
                <Tabs.List>
                  <Tabs.Tab value="sandbox" className="tab active-tab" data-cy="sandbox-tab">
                    For Production
                  </Tabs.Tab>
                </Tabs.List>
              </Tabs>
              <Text>
                Quick help links:{' '}
                <a href="https://www.google.com/" target="_blank" rel="noreferrer">
                  Learn more about webhooks
                </a>
              </Text>
            </Flex>
            {activatedIntegrations?.map((activatedIntegration) => {
              return (
                <AppDetailsCard
                  integrationDetails={getIntegrationDetailsOfActivatedIntegration(
                    activatedIntegration,
                  )}
                  integrationDefinitions={integrationDefinitions}
                  activatedIntegration={activatedIntegration}
                  isIntegrationReadyToGoLive={isIntegrationReadyToGoLive(activatedIntegration)}
                  refetchGetAppStoreDetails={refetchGetAppStoreDetails}
                  refetchGetReadyToGoLiveIntegrations={refetchGetReadyToGoLiveIntegrations}
                  key={activatedIntegration.integration.id}
                  disabledAllFields={isRequestAllreadySent(activatedIntegration)}
                  refetchGetIntegrationsWithGoLiveStatuses={
                    refetchGetIntegrationsWithGoLiveStatuses
                  }
                />
              );
            })}
          </Flex>
        </Box>
      </PageLayout>
    </>
  );
};

export default AppStoreDetails;
