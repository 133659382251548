import { apiKeyAxios } from '../config/axios.config';
import { ApiKey, ApiKeyGenerationRequest } from '../models/apiKey';

const apikeyGoLiveService = {
  getApiKeysbyIntegrationAndOrganizationId: async (
    integrationId: string,
    organizationId: string,
  ): Promise<ApiKey[]> => {
    const { data } = await apiKeyAxios.get(`/go-live/keys/${integrationId}/${organizationId}`);
    return data;
  },
  generateApiKey: async (payload: ApiKeyGenerationRequest): Promise<ApiKey> => {
    const { data } = await apiKeyAxios.post(`/go-live/keys`, payload);
    return data;
  },
};

export default apikeyGoLiveService;
