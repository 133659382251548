import { MantineTheme, Sx } from '@mantine/core';

export const agreementModalStyles = (theme: MantineTheme): Sx => {
  return {
    '.mantine-Modal-inner': {
      '.mantine-Paper-root': {
        overflowX: 'hidden',
      },
    },
  };
};

export const agreementModalContent = (theme: MantineTheme): Sx => {
  return {
    '.pdf-document': {
      '.mantine-Paper-root': {
        padding: 'auto',
        marginBottom: '24px',
      },
    },
    '.footer': {
      display: 'flex',
      flexDirection: 'row',
      position: 'sticky',
      bottom: 0,
      width: '100%',
      backgroundColor: theme.colors.grubtech[3],
      padding: theme.spacing.md,
      justifyContent: 'space-between',
      alignItems: 'center',
      zIndex: 10,
    },
  };
};
