import { userAxios } from '../config/axios.config';
import { UserData } from '../models';

const userService = {
  getUserByEmail: async (email: string): Promise<UserData> => {
    const { data } = await userAxios.get(`/byUserEmail/${email}`);
    return data;
  },
};

export default userService;
