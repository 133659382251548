import { appStoreDetailsAxios } from '../config/axios.config';
import { IntegrationId } from '../models';
import { AppStoreDetails, IntegrationWithGoLiveStatus } from '../models/appStoreDetails';

const appStoreDetailsGoLiveService = {
  getReadyToGoLiveIntegrations: async (organizationId: string): Promise<IntegrationId[]> => {
    const { data } = await appStoreDetailsAxios.get(
      `/ready-to-go-live-integrations/${organizationId}`,
    );
    return data;
  },

  getIntegrationsWithGoLiveStatuses: async (
    organizationId: string,
  ): Promise<IntegrationWithGoLiveStatus[]> => {
    const { data } = await appStoreDetailsAxios.get(
      `/integration-with-go-live-status/${organizationId}`,
    );
    return data;
  },

  getAppStoreDetails: async (organizationId: string): Promise<AppStoreDetails> => {
    const { data } = await appStoreDetailsAxios.get(`/${organizationId}`);
    return data;
  },

  requestToGoLive: async (payload: {
    organizationId: string;
    integrationId: string;
  }): Promise<void> => {
    const { data } = await appStoreDetailsAxios.post(
      `/request-to-go-live/${payload.organizationId}/${payload.integrationId}`,
    );
    return data;
  },

  uploadApplicationImage: async (
    organizationId: string,
    file: File,
    integrationId: String,
  ): Promise<void> => {
    const formData = new FormData();
    formData.append('file', file);
    await appStoreDetailsAxios.post(
      `/upload-application-image/${organizationId}/${integrationId}`,
      formData,
    );
    return;
  },

  saveName: async (payload: {
    organizationId: string;
    integration: IntegrationId;
    applicationName: string;
  }): Promise<void> => {
    const { data } = await appStoreDetailsAxios.post(`/save-name/${payload.organizationId}`, {
      applicationName: payload.applicationName,
      integration: payload.integration,
    });
    return data;
  },
};

export default appStoreDetailsGoLiveService;
