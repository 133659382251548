import { ndaAxios } from '../config/axios.config';
import { DataWithFileName, NdaStatus } from '../models/nda';

const ndaService = {
  downloadInitialPdf: async (): Promise<ArrayBuffer> => {
    const data = await ndaAxios.post(
      '/download',
      {},
      {
        responseType: 'arraybuffer',
      },
    );
    return data.data;
  },
  downloadSignedPdf: async (organizationId: string): Promise<DataWithFileName> => {
    const data = await ndaAxios.post(
      `/download/${organizationId}`,
      {},
      {
        responseType: 'arraybuffer',
      },
    );

    const contentDisposition = data.headers['content-disposition'];
    return { data: data.data, fileName: contentDisposition };
  },
  getNdaStatus: async (organizationId: string): Promise<NdaStatus> => {
    const data = await ndaAxios.get(`/status/${organizationId}`);
    return data.data;
  },

  uploadFile: async (organizationId: string, file: File): Promise<void> => {
    const formData = new FormData();
    formData.append('file', file);
    await ndaAxios.post(`/upload/${organizationId}`, formData);
    return;
  },
};

export default ndaService;
