import { WebHookDefinition, WebHookValue } from '../../models';
import { WebhookAction } from '../payloadModels/webhook';
import { WebhookActionTypes } from '../types';

export type WebhookState = {
  webhookDefinitions: WebHookDefinition[];
  webhookValues: WebHookValue[];
  checkIsProductionenabled: Boolean;
  webhookValuesForProduction: WebHookValue[];
};

const initialState: WebhookState = {
  webhookDefinitions: [],
  webhookValues: [],
  checkIsProductionenabled: false,
  webhookValuesForProduction: [],
};

export const webhookReducer = (state = initialState, action: WebhookAction): WebhookState => {
  switch (action.type) {
    case WebhookActionTypes.CHANGE_WEBHOOK_DEFINITIONS:
      const webhookDefinitions = action.payload as WebHookDefinition[];
      const integrationIdOfWebhookDefinitions = webhookDefinitions[0].integration.id;
      const otherWebhookDefinitions = state.webhookDefinitions.filter(
        (webhookDefinition) =>
          webhookDefinition.integration.id !== integrationIdOfWebhookDefinitions,
      );
      return { ...state, webhookDefinitions: [...otherWebhookDefinitions, ...webhookDefinitions] };
    case WebhookActionTypes.CHANGE_WEBHOOK_VALUES:
      return { ...state, webhookValues: action.payload as WebHookValue[] };
    case WebhookActionTypes.CHANGE_CHECK_IS_PRODUCTION_ENABLED:
      return { ...state, checkIsProductionenabled: action.payload as Boolean };
    case WebhookActionTypes.CHANGE_WEBHOOK_VALUES_FOR_PRODUCTION:
      return { ...state, webhookValuesForProduction: action.payload as WebHookValue[] };
    default:
      return state;
  }
};
