import {
  Box,
  Divider,
  Flex,
  Image,
  List,
  LoadingOverlay,
  Paper,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { sandboxActionCreator, testFrameworkActionCreator } from '../../../redux/actions';
import { RootState } from '../../../redux/common';
import { AuthenticationState, IntegrationState, TestFrameworkState } from '../../../redux/reducers';
import { sandboxService, testFrameworkService } from '../../../service';
import Instruction from './Instruction';
import Status from './Status';
import { overviewStyles } from './style';
import {
  instruction1,
  instruction2,
  instruction3,
  instruction4,
  testOverviewMain,
} from '../../../assets/images/testFramework';
import ReleaseNotes from './ReleaseNotes';
import { IntegrationActivationStatus, TestSuiteApprovalStatus } from '../../../models';

const Overview: React.FC = () => {
  const theme = useMantineTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    selectedTestSuite,
    testSuiteDefinitions,
    selectedTestCase,
    testSuiteInstanceData,
    testSubmittedDetails,
  } = useSelector<RootState, TestFrameworkState>((state: RootState) => {
    return state.testFramework;
  });

  const { userData } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });

  const { activatedIntegrations } = useSelector<RootState, IntegrationState>((state: RootState) => {
    return state.integration;
  });

  const isThereAnyIntegrationTestCasesApprovedByAdmin = activatedIntegrations.some(
    (activatedIntegration) =>
      activatedIntegration.status.includes(
        IntegrationActivationStatus.TEST_CASES_APPROVED_BY_ADMIN,
      ),
  );

  const instructions = [
    {
      image: instruction1,
      number: 1,
      title: 'Preparation',
      description: (
        <>
          <Text className="instruction-description-text">
            Before you can test this API, make sure you have:
          </Text>
          <List size="sm" type="ordered">
            <List.Item>Created your API credentials </List.Item>
            <List.Item>Configured webhook URLs for this API</List.Item>
            <List.Item>Saved webhook secrets</List.Item>
            <List.Item>Created a sandbox site to test this API with</List.Item>
          </List>
        </>
      ),
      links: [
        {
          text: 'Go to create API credentials',
          url: '/credentials',
          dataCy: 'create-API-credentials',
        },
        {
          text: 'Go to configure webhooks and get secrets',
          url: '/webhooks',
          dataCy: 'config-webhooks',
        },
        {
          text: 'Go to create a sandbox site',
          url: '/sandbox-sites',
          dataCy: 'create-sandboxsite',
        },
      ],
    },
    {
      image: instruction2,
      number: 2,
      title: 'Test the scenarios',
      description: `Test against each of the scenarios in your sandbox environment before connecting to production.\n\n This is to make sure that your orders are recieved and order events are reflected accurately on your application and on Grubtech platform. 
  
      You will be required to complete all test scenarios and submit the UAT results for review by our Grubtech administrator. 
      
      Once reviewed you will be notified via email whether the UAT has been approved or resubmission of certain information is required in order to complete the UAT verification. If a resubmission is required, you will be able to re-run and resubmit the details for the relevant test scenarios that are highlighted, this process can be followed until the UAT is approved.`,
      buttonText: testSuiteInstanceData ? 'Restart UAT' : 'Start UAT',
      buttonColor: testSuiteInstanceData ? 'red' : '',
      isButtonDisabled:
        testSuiteInstanceData?.submittedForAdminApproval &&
        !(
          testSubmittedDetails?.status === TestSuiteApprovalStatus.ADMIN_APPROVED ||
          testSubmittedDetails?.status === TestSuiteApprovalStatus.ADMIN_REJECTED
        ),
      //   ||
      // testSubmittedDetails?.status === TestSuiteApprovalStatus.ADMIN_APPROVED,
    },
    {
      image: instruction3,
      number: 3,
      title: "Make sure you're eligible for Production access",
      description:
        'We should have reviewed you activation contract by now. If not, send us a message to chase for this.',
      links: [
        {
          text: 'Check your NDA status',
          url: '/nda',
          dataCy: 'check-nda-status',
        },
      ],
    },
    {
      image: instruction4,
      number: 4,
      title: 'Go live',
      description:
        'Production environment will be enabled once your contract is NDA and UAT results are approved.\n Generate your production credentials the same way you did with the test ones, then send us the details of the application(s) to be published on the Grubcenter app store by filling in these details.',
      links: [
        {
          text: 'Go to application publishing',
          url: '/app-store-details',
          dataCy: 'check-nda-status',
          isDisabled: !isThereAnyIntegrationTestCasesApprovedByAdmin,
        },
      ],
      descriptionPartTwo:
        'You will be able to make a request to go-live and once our administrator approves your request, you will receive the confirmation via email.',
    },
  ];

  useEffect(() => {
    if (!selectedTestCase) {
      dispatch(testFrameworkActionCreator.changeSelectedFlow(null));
      dispatch(testFrameworkActionCreator.changeSelectedSubFlow(null));
      dispatch(testFrameworkActionCreator.changeselectedTestCase(null));
      dispatch(testFrameworkActionCreator.changeTestCaseExecutionData(null));
      dispatch(testFrameworkActionCreator.changeTestSuiteIntanceData(null));
    }
    // eslint-disable-next-line
  }, []);

  const { isLoading: isLoadingSanboxStatus } = useQuery({
    queryKey: ['sandbox-summary-data-overview', userData.organizationId],
    queryFn: () => sandboxService.getSandboxStatus(userData.organizationId),
    onSuccess: (data) => {
      dispatch(sandboxActionCreator.changeSandboxSummaryData(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const getTestSuitesByIntegration = useMutation({
    mutationFn: (integartion: string) => {
      return testFrameworkService.getTestSuitesByIntegration(integartion);
    },
    onSuccess: (data) => {
      dispatch(testFrameworkActionCreator.changeTestSuiteDefinitionData(data));
      navigate(`/uat/${selectedTestSuite}/test-scenarios`);
    },
    onError: (error: any) => {
      navigate(`/uat/${selectedTestSuite}/test-scenarios`);
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const beginTestSuite = useMutation({
    mutationFn: (suiteId: string) => {
      return testFrameworkService.beginTestSuite(suiteId);
    },
    onSuccess: (data) => {
      dispatch(testFrameworkActionCreator.changeTestSuiteIntanceData(data));
      dispatch(testFrameworkActionCreator.changeTestCaseExecutionData(null));
      getTestSuitesByIntegration.mutate(
        testSuiteDefinitions.find((suiteDef) => suiteDef.id === selectedTestSuite)?.integration ??
          '',
      );
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const getselectedDefinition = useCallback(() => {
    return testSuiteDefinitions.find(({ id }) => id === selectedTestSuite);
  }, [selectedTestSuite, testSuiteDefinitions]);

  const onClickBegin = () => {
    const testSuiteData = testSuiteDefinitions.find(({ id }) => id === selectedTestSuite);
    if (testSuiteData) {
      beginTestSuite.mutate(testSuiteData.id);
    }
  };

  return (
    <Box sx={overviewStyles(theme)}>
      <LoadingOverlay
        visible={
          beginTestSuite.isLoading || isLoadingSanboxStatus || getTestSuitesByIntegration.isLoading
        }
      />
      <Flex gap={theme.spacing.xl}>
        <Flex direction={'column'} gap={theme.spacing.xl} className="overview-status">
          <Status />
          <ReleaseNotes />
        </Flex>
        <Paper shadow="xs" className="overview-instructions-paper">
          <Flex justify="space-between" gap={theme.spacing.xl}>
            <div>
              <Title order={2}>{`Integrating with ${getselectedDefinition()?.name}`}</Title>
              <Text className="overview-description">
                {selectedTestSuite === 'FA_TEST_SUITE'
                  ? 'This integration suite enables food aggregators to send orders to Grubtech platform, in real time and receive real time order updates.'
                  : 'This integration suite enables applications such as POS systems to integrate with Grubtech platform, in real time, to receive orders and send delivery progress updates.'}
              </Text>
            </div>
            <Image
              className="overview-image"
              src={testOverviewMain}
              alt="orders api"
              width={220}
              height={125}
            />
          </Flex>
          <Divider className="overview-divider" />
          <Title className="overview-instructions-title" order={3}>
            Instructions
          </Title>
          <div className="overview-instructions">
            {instructions.map((instruction) => (
              <Instruction key={instruction.number} {...instruction} onClickBegin={onClickBegin} />
            ))}
          </div>
        </Paper>
      </Flex>
    </Box>
  );
};

export default Overview;
