import { Tooltip } from '@mantine/core';
import { IconAlertCircleFilled, IconLock } from '@tabler/icons-react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';

interface SideBarLinkProps {
  to: string;
  item: any;
  disabled?: boolean;
  onClick?: () => void;
  disabledText?: string;
  isWarning?: boolean;
  warningText?: string;
}

const SideBarLink: React.FC<SideBarLinkProps> = ({
  to,
  item,
  disabled = false,
  onClick,
  disabledText,
  isWarning,
  warningText,
}) => {
  const resolvedPath = useResolvedPath(to);
  const match = useMatch({ path: resolvedPath.pathname, end: false });

  return (
    <Link
      className={`sidebar-link ${match ? 'sidebar-link-active' : ''} ${
        disabled ? 'sidebar-link-disabled' : ''
      }`}
      to={item.link}
      key={item.label}
      onClick={(e) => {
        disabled && e.preventDefault();
        !disabled && onClick && onClick();
      }}
      data-cy={`${item.label}-link`}
    >
      <item.icon className="sidebar-link-icon" stroke={2} size={20} />
      <span>{item.label}</span>
      {disabled && (
        <Tooltip
          label={disabledText ? disabledText : 'Please create an organisation first'}
          color="gray"
        >
          <IconLock size={20} className="sidebar-link-lock" />
        </Tooltip>
      )}
      {isWarning && (
        <Tooltip label={warningText ? warningText : ''} color="gray">
          <IconAlertCircleFilled size={20} className="sidebar-link-warning" />
        </Tooltip>
      )}
    </Link>
  );
};

export default SideBarLink;
