import { Box, Image, Paper, Text, Title, useMantineTheme } from '@mantine/core';
import { releaseNotesStyles } from './style';
import { overview_status } from '../../../../assets/images/testFramework';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/common';
import { TestFrameworkState } from '../../../../redux/reducers';

const ReleaseNotes: React.FC = () => {
  const theme = useMantineTheme();

  const { selectedTestSuite } = useSelector<RootState, TestFrameworkState>((state: RootState) => {
    return state.testFramework;
  });

  return (
    <Box sx={releaseNotesStyles(theme)}>
      <Paper shadow="xs" className="release-notes-paper">
        <Image
          className="release-notes-image"
          src={overview_status}
          alt="release notes"
          width={75}
          height={75}
        />
        <Title order={3}>Latest release notes</Title>
        <Text className="release-notes-text">
          {selectedTestSuite === 'FA_TEST_SUITE'
            ? 'This integration suite enables food aggregators to send orders to Grubtech platform, in real time and receive real time order updates.'
            : 'This integration suite enables applications such as POS systems to integrate with Grubtech platform, in real time, to receive orders and send delivery progress updates.'}
        </Text>
        {/* <CustomLink to="#" text="Read more" target="_blank" dataCy="release-notes-link" withIcon /> */}
      </Paper>
    </Box>
  );
};

export default ReleaseNotes;
