import { webhookAxios } from '../config/axios.config';
import { WebHookDefinition, WebHookValue } from '../models/webhook';

const webhookService = {
  getWebHookDefinitions: async (integrationId: string): Promise<WebHookDefinition[]> => {
    const { data } = await webhookAxios.get(`/definitions/${integrationId}`);
    return data;
  },
  persistWebHookValues: async (payload: {
    integrationId: string;
    partnerId: string;
    values: WebHookValue[];
  }): Promise<void> => {
    await webhookAxios.post(`/values/${payload.integrationId}/${payload.partnerId}`, {
      organizationId: payload.partnerId,
      webHookValues: payload.values,
    });
  },
  getWebHookValues: async (integrationId: string, partnerId: string): Promise<WebHookValue[]> => {
    const { data } = await webhookAxios.get(`/values/${integrationId}/${partnerId}`);
    return data.webHookValues;
  },

  checkIsProductionEnabledByIntegration: async (
    integrationId: string,
    organizationId: string,
  ): Promise<Boolean> => {
    const { data } = await webhookAxios.get(
      `/is-production-enabled-by-integration/${integrationId}/${organizationId}`,
    );
    return data;
  },

  checkIsProductionEnabled: async (organizationId: string): Promise<Boolean> => {
    const { data } = await webhookAxios.get(`/is-production-enabled/${organizationId}`);
    return data;
  },

  checkIsProductionRequired: async (organizationId: string): Promise<Boolean> => {
    const { data } = await webhookAxios.get(`/is-production-required/${organizationId}`);
    return data;
  },
};

export default webhookService;
