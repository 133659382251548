import {
  Box,
  Button,
  LoadingOverlay,
  Text,
  TextInput,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { FC, useCallback, useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useDispatch, useSelector } from 'react-redux';
import { number as validateNumber, object as validateObject, string as validateString } from 'yup';
import PageLayout from '../../../components/PageLayout';
import { AddressBuilderField, Organization } from '../../../models';
import { organizationActionCreator } from '../../../redux/actions/organization';
import { RootState } from '../../../redux/common';
import { AuthenticationState, OrganizationState } from '../../../redux/reducers';
import { commonService } from '../../../service';
import organizationService from '../../../service/organizations.service';
import { urlValidationRegex } from '../../../utils/string';
import { detailPageStyles } from './styles';

export type DetailPageProps = {
  id: string;
};

export const getValidationSchema = (fields: AddressBuilderField[]): unknown => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const schema: any = {};

  fields.forEach((item) => {
    //Translated required message is used, if item.requiredMsg is defined from the original component using AddressBuilder.tsx
    const requiredMsg = item.requiredMsg || 'is required';
    const requiredValidation = item.label + ' ' + requiredMsg;
    if ('optional' in item) {
      if (!item.optional) {
        schema[item.name] = validateString().required(requiredValidation).trim(requiredValidation);
      }
    } else {
      schema[item.name] = validateString().required(requiredValidation).trim(requiredValidation);
    }
  });

  return validateObject().shape(schema);
};

const DetailPage: FC<DetailPageProps> = ({ id }) => {
  const theme = useMantineTheme();
  const dispatch = useDispatch();
  const { organization, countries } = useSelector<RootState, OrganizationState>(
    (state: RootState) => {
      return state.organization;
    },
  );

  const { userData } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });

  // const [addressFieldValues, setAddressFieldValues] = useState<AddressBuilderField[]>([]);

  // const addressFieldsFormikInstance = useFormik({
  //   initialValues: {} as FormikValues,
  //   validationSchema: getValidationSchema(addressFieldValues),
  //   onSubmit: () => {
  //     return;
  //   },
  // });

  // useEffect(() => {
  //   addressFieldValues.forEach(({ name, value }) =>
  //     addressFieldsFormikInstance.setFieldValue(name, value),
  //   );
  //   // eslint-disable-next-line
  // }, [addressFieldValues]);

  const generateInitialValues = useCallback(() => {
    return {
      name: organization?.name,
      // countryId: organization?.countryId,
      contactInfo: organization?.contactInfo,
      companyWebsite: organization?.companyWebsite,
      agreedTermId: organization?.agreedTermId,
      address: organization?.address,
      invitationCode: organization?.invitationCode,
      // cityId: organization?.cityId,
      // locationCoordinates: {
      //   latitude: organization?.locationCoordinates?.latitude ?? 0,
      //   longitude: organization?.locationCoordinates?.longitude ?? 0,
      // },
    };
  }, [organization]);

  useEffect(() => {
    setValues(generateInitialValues());
    // eslint-disable-next-line
  }, [organization]);

  const { values, setFieldValue, isValid, setValues, handleBlur, touched, errors, setTouched } =
    useFormik({
      initialValues: generateInitialValues(),
      onSubmit: () => {},
      validationSchema: validateObject().shape({
        name: validateString().trim().required('Name required'),
        contactInfo: validateObject().shape({
          name: validateString().trim().required('Contact name required'),
          email: validateString()
            .trim()
            .required('contact email required')
            .email('Need to be valid email'),
          telephone: validateObject().shape({
            countryCode: validateString().trim().required('Country code required'),
            number: validateNumber().required('Number required'),
          }),
        }),
        companyWebsite: validateString()
          .trim()
          .required('Company Website required')
          .matches(urlValidationRegex, 'Must be a valid url')
          .url('Must be a valid url'),
        agreedTermId: validateString().trim().required('Agreed Tem Id required'),
        // countryId: validateString().trim().required('Country required'),
        // cityId: validateString().trim().required('City required'),
        // locationCoordinates: validateObject().shape({
        //   latitude: validateNumber()
        //     .min(-90, 'latitude must be within from -90 to 90 range')
        //     .max(90, 'latitude must be within from -90 to 90 range')
        //     .required('latitude is required'),
        //   longitude: validateNumber()
        //     .min(-180, 'longitude must be within from -180 to 180 range')
        //     .max(180, 'longitude must be within from -180 to 180 range')
        //     .required('longitude is required'),
        // }),
      }),
    });

  const { isLoading: isLoadingCountries } = useQuery({
    queryKey: ['get-countries'],
    queryFn: () => commonService.getAllCountries(),
    onSuccess: (data) => {
      dispatch(organizationActionCreator.changeCountries(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  // const { isLoading: isLoadingCities, refetch: refetchCities } = useQuery({
  //   queryKey: ['get-cities-by-Country-id', values?.countryId],
  //   queryFn: () => commonService.getCitiesByCountryId(values?.countryId),
  //   onSuccess: (data) => {
  //     dispatch(organizationActionCreator.changeCities(data));
  //   },
  //   onError: (error: any) => {
  //     notifications.show({
  //       title: error.name ?? 'Something went wrong',
  //       message: error.message ?? 'Something went wrong',
  //       autoClose: 2000,
  //       color: 'red',
  //     });
  //   },
  //   enabled: values?.countryId && values?.countryId?.length > 0 ? true : false,
  // });

  // const getAddressValue = (countryId: string, addressFieldId: string) => {
  //   if (countryId === values.countryId) {
  //     return (
  //       organization?.address?.fields?.find((field) => field?.addressFieldId === addressFieldId)
  //         ?.value || ''
  //     );
  //   }
  //   return '';
  // };

  const { isLoading: isLoadingOrganization } = useQuery({
    queryKey: ['get-organization'],
    queryFn: () => organizationService.getOrganization(userData.organizationId || ''),
    onSuccess: (data) => {
      // if (values.countryId === data.countryId) {
      //   data?.address?.fields?.forEach((item) => {
      //     addressFieldsFormikInstance.setFieldValue(item.addressFieldId, item.value);
      //   });
      // }
      // getAddressFieldsByCountry.mutate(data.countryId);
      dispatch(organizationActionCreator.changeOrganization(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const updateOrganization = useMutation({
    mutationFn: (organization: Organization) => {
      return organizationService.updateOrganization(organization);
    },
    onSuccess: (data) => {
      dispatch(organizationActionCreator.changeUpdateOrganization(data));
      notifications.show({
        message: 'Organization is updated successfully',
        color: 'green',
      });
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const isSaveDisabled = () => {
    return !isValid;
  };

  // const getAddressFieldsByCountry = useMutation({
  //   mutationFn: (countryId: string) => {
  //     return organizationService.getAddressFieldsByCountry(countryId);
  //   },
  //   onSuccess: (data) => {
  //     const addressBuilderFields =
  //       data &&
  //       data?.addressFieldWithOptionalInfoList.map((item) => {
  //         return {
  //           label: formatAddressFieldLabel(item.addressField.displayName),
  //           name: item.addressField.id,
  //           value: getAddressValue(item.countryId, item.addressField.id),
  //           optional: item.isOptional,
  //           requiredMessage: 'is required',
  //         };
  //       });
  //     if (addressBuilderFields) {
  //       setAddressFieldValues(addressBuilderFields);
  //     }
  //     dispatch(organizationActionCreator.changeAddressFields(data));
  //   },
  //   onError: (error: any) => {
  //     notifications.show({
  //       title: error.name ?? 'Something went wrong',
  //       message: error.message ?? 'Something went wrong',
  //       color: 'red',
  //     });
  //   },
  // });

  // const removeAddressFields = () => {
  //   Object.keys(addressFieldsFormikInstance.values).forEach((item: string) => {
  //     addressFieldsFormikInstance.setFieldValue(item, '');
  //   });
  // };

  const getUpdatedOrganization = () => {
    let currentOrganization: Organization = {
      ...organization,
      // countryId: values.countryId,
      // cityId: values.cityId,
      contactInfo: {
        name: values.contactInfo.name,
        email: values.contactInfo.email,
        telephone: {
          countryCode: values.contactInfo.telephone.countryCode,
          number: values.contactInfo.telephone.number,
        },
      },
      // address: {
      //   fields: Object.keys(addressFieldsFormikInstance.values).map((item: string) => ({
      //     addressFieldId: item,
      //     value: addressFieldsFormikInstance.values[item],
      //   })),
      //   countryAddressFormatId: addressFields.countryAddressFormatId,
      // },
      // locationCoordinates: values.locationCoordinates,
    };
    return currentOrganization;
  };

  // const getAddressBuilder = useCallback(() => {
  //   return (
  //     <AddressBuilder fields={addressFieldValues} formikInstance={addressFieldsFormikInstance} />
  //   );
  //   // eslint-disable-next-line
  // }, [values, addressFieldValues, addressFieldsFormikInstance]);

  const getPhoneInput = useCallback(() => {
    if (countries.length > 0)
      return (
        <PhoneInput
          onlyCountries={countries.map((country) => country.alpha2Code.toLowerCase())}
          inputClass="phone-input"
          country="uae"
          onChange={(
            phone,
            country: {
              countryCode: string;
              dialCode: string;
              format: string;
              name: string;
            },
          ) => {
            setFieldValue('contactInfo.telephone.countryCode', country.dialCode);
            setFieldValue('contactInfo.telephone.number', phone.substring(country.dialCode.length));
          }}
          value={
            (values.contactInfo?.telephone?.countryCode ?? '') +
            (values.contactInfo?.telephone?.number?.toString() ?? '')
          }
          countryCodeEditable={false}
          enableSearch={true}
          disableSearchIcon={true}
          inputProps={{
            name: 'phone',
            required: true,
            autoFocus: true,
          }}
          onBlur={() => {
            setTouched({
              ...touched,
              contactInfo: {
                ...touched.contactInfo,
                telephone: {
                  ...touched.contactInfo?.telephone,
                  number: true,
                  countryCode: true,
                },
              },
            });
          }}
        />
      );

    return null;
    // eslint-disable-next-line
  }, [countries, values, errors, touched]);

  return (
    <PageLayout
      id={`detail-page-${id}`}
      sx={detailPageStyles(
        theme,
        ((touched?.contactInfo?.telephone?.number &&
          (errors?.contactInfo?.telephone?.number ?? '')?.length > 0) ||
          (touched?.contactInfo?.telephone?.countryCode &&
            (errors?.contactInfo?.telephone?.countryCode ?? '')?.length > 0)) ??
          false,
      )}
    >
      <LoadingOverlay
        visible={isLoadingOrganization || isLoadingCountries || updateOrganization.isLoading}
      />
      <Box className="content">
        <div className="header">
          <Title order={2} className="title" data-cy="organisation-details-title">
            Organisation Details
          </Title>
          <Button
            onClick={() => updateOrganization.mutate(getUpdatedOrganization())}
            disabled={isSaveDisabled()}
          >
            Save Changes
          </Button>
        </div>
        <div className="content">
          <TextInput placeholder="Company name" value={values.name} label="Company Name" disabled />
          <TextInput
            placeholder="Contact name"
            label="Contact Name"
            withAsterisk
            value={values.contactInfo?.name}
            onChange={(e) => {
              setFieldValue('contactInfo.name', e.target.value);
            }}
            onBlur={handleBlur}
            name={'contactInfo.name'}
            error={touched?.contactInfo?.name && errors?.contactInfo?.name}
          />
          <TextInput
            placeholder="Contact email"
            label="Contact Email"
            withAsterisk
            value={values.contactInfo?.email}
            onChange={(e) => {
              setFieldValue('contactInfo.email', e.target.value);
            }}
            disabled={true}
            onBlur={handleBlur}
            name={'contactInfo.email'}
            error={touched?.contactInfo?.email && errors?.contactInfo?.email}
          />

          <div className="phone-container">
            <Text fz="sm" fw={700} mb={8}>
              Contact Phone Number <span>*</span>
            </Text>
            {getPhoneInput()}
            {touched?.contactInfo?.telephone?.number && errors?.contactInfo?.telephone?.number && (
              <Text className="error-message" fz="sm">
                {errors?.contactInfo?.telephone?.number}
              </Text>
            )}
            {touched?.contactInfo?.telephone?.countryCode &&
              errors?.contactInfo?.telephone?.countryCode && (
                <Text className="error-message" fz="xs">
                  {errors?.contactInfo?.telephone?.countryCode}
                </Text>
              )}
          </div>

          <TextInput
            placeholder="Company website"
            label="Company Website"
            value={values.companyWebsite}
            disabled
          />
          {/* <SimpleGrid cols={2}>
            <Select
              searchable
              label="Country"
              withAsterisk
              placeholder="Select country"
              data={getCountryList()}
              value={values.countryId}
              name="countryId"
              onChange={(value) => {
                addressFieldsFormikInstance.resetForm();
                removeAddressFields();
                getAddressFieldsByCountry.mutate(value || '');
                setFieldValue('countryId', value);
                setFieldValue('cityId', '');
                setFieldValue('locationCoordinates', {
                  latitude: 0,
                  longitude: 0,
                });
                refetchCities();
              }}
              onBlur={handleBlur}
              error={touched?.countryId && errors?.countryId}
            />
            <Select
              label="City"
              withAsterisk
              placeholder="Select city"
              data={
                cities?.map((city) => {
                  return {
                    value: city.id,
                    label: city.name,
                  };
                }) ?? []
              }
              value={values.cityId}
              name="cityId"
              onChange={(value) => {
                setFieldValue('cityId', value);
                setFieldValue('locationCoordinates', {
                  latitude: cities.find((city) => city.id === value)?.cityCoordinates?.latitude,
                  longitude: cities.find((city) => city.id === value)?.cityCoordinates?.longitude,
                });
              }}
              onBlur={handleBlur}
              error={touched?.cityId && errors?.cityId}
            />
          </SimpleGrid>
          <SimpleGrid cols={2}>
            <TextInput
              placeholder="latitude"
              label="Latitude"
              value={values.locationCoordinates.latitude}
              disabled
            />
            <TextInput
              placeholder="longitude"
              label="Longitude"
              value={values.locationCoordinates.longitude}
              disabled
            />
          </SimpleGrid>

          {getAddressBuilder()} */}
        </div>
      </Box>
    </PageLayout>
  );
};

export default DetailPage;
