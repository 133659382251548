import { MantineTheme, Sx } from "@mantine/core";

export const definitionSettingsCardStyles = (theme: MantineTheme): Sx => {
  return {
    '.title': {
      marginBottom: theme.spacing.xs,
      color: '#7787F3'
    },
    '.definition': {
      marginBottom: theme.spacing.xl,
      fontSize: theme.fontSizes.sm,
    },
    '.status-title': {
      marginBottom: theme.spacing.xs,
    },
    '.sub-status': {
      marginBottom: theme.spacing.xl,
    },
    '.sub-status-title': {
      fontWeight: 'bold',
      fontSize: theme.fontSizes.sm,
      width: '50%'
    },
    '.sub-status-element': {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: theme.fontSizes.sm,
      marginBottom: theme.spacing.xs,
    },
    '.sub-status-title-container': {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      marginTop: '20px',
      marginBottom: '10px'
    },
    '.sub-status-data-container': {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      marginBottom: '5px'
    },
    '.sub-status-element-text': {
      fontSize: theme.fontSizes.sm,
      width: '50%',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '.button-section': {
      padding: `0 ${theme.spacing.md}`,
      marginBottom: '16px',
      display: 'flex',
      justifyContent: 'center',
    },
  };
  };