import { Sx, MantineTheme } from '@mantine/core';

export const ndaDocumentPageStyles = (theme: MantineTheme): Sx => {
  return {
    '.nda-page-container': {
      width: '100%',
      marginTop: '20px',
    },
    '.nda-page-content': {
      background: '#ffff',
      padding: '1.5rem',
      width: '80%',
    },
    '.main-title': {
      fontSize: '20px',
      fontWeight: 700,
      marginBottom: '5px',
    },
    '.regular-text': {
      fontSize: '16px',
      marginBottom: '16px',
      marginLeft: '8px',
    },
    '.sub-title': {
      fontSize: '20px',
    },
    '.nda-button': {
      marginBottom: '53px',
      width: '182px',
      marginLeft: '16px',
    },
    '.nda-button-content': {
      width: '20%',
      marginLeft: '36px',
      padding: '1.5rem',
      background: '#ffff',
      height: 'fit-content',
      marginRight: '10px',
    },
    '.nda-pdf-document': {
      width: 'fit-content',
      height: '500px',
      overflowY: 'auto',
    },
    '.footer': {
      display: 'flex',
      flexDirection: 'row',
      position: 'sticky',
      bottom: 0,
      width: '100%',
      backgroundColor: theme.colors.grubtech[3],
      padding: theme.spacing.md,
      justifyContent: 'flex-end',
      alignItems: 'center',
      zIndex: 10,
    },
  };
};
