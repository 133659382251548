import { Sx, MantineTheme } from '@mantine/core';

export const sidebarStyles = (theme: MantineTheme): Sx => {
  return {
    '.sidebar-header': {
      paddingBottom: theme.spacing.md,
    },

    '.sidebar-header-title': {
      fontSize: theme.fontSizes.sm,
      color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[8],
      width: 150,
      textAlign: 'right',
      marginTop: -15,
    },

    '.sidebar-footer': {
      paddingTop: theme.spacing.md,
      marginTop: theme.spacing.md,
      borderTop: `1px solid ${theme.colors.gray[2]}`,
      display: 'inline-flex',
      alignItems: 'center',

      '.mantine-Text-root': {
        fontSize: '0.875rem',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
    },

    '.sidebar-link': {
      ...theme.fn.focusStyles(),
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      fontSize: theme.fontSizes.sm,
      color: theme.colors.gray[7],
      padding: '10px 12px',
      borderRadius: theme.radius.sm,
      fontWeight: 500,

      '&:hover': {
        color: theme.colors.gray[7],
      },

      '&:hover .sidebar-link-icon': {
        color: theme.colors.grubtech[1],
      },
    },

    '.sidebar-link-disabled': {
      '&:hover': {
        color: theme.colors.gray[7],
      },

      '&:hover .sidebar-link-icon': {
        color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[5],
      },
    },

    '.sidebar-link-icon': {
      color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[5],
      marginRight: theme.spacing.sm,
    },

    '.sidebar-link-lock': {
      color: theme.colors.gray[6],
      marginLeft: 'auto',
    },

    '.sidebar-link-warning': {
      color: theme.colors.yellow[3],
      marginLeft: 'auto',
    },

    '.sidebar-link-active': {
      color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      fontWeight: 'bold',

      '& .sidebar-link-icon': {
        color: theme.colors.grubtech[1],
      },
    },

    '.sidebar-external-link': {
      ...theme.fn.focusStyles(),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      textDecoration: 'none',
      fontSize: theme.fontSizes.sm,
      color: theme.colors.gray[7],
      padding: '8px 12px',
      borderRadius: theme.radius.sm,
      fontWeight: 500,

      '.sidebar-external-link-box': {
        display: 'flex',
        alignItems: 'center',

        '.sidebar-external-link-icon': {
          marginRight: theme.spacing.xs,
        },
      },

      '&:hover': {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
      },

      '&:hover .sidebar-external-link-icon': {
        color: theme.colors.grubtech[1],
      },
    },

    '.sidebar-external-link-icon': {
      color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[5],
    },

    '.sidebar-footer-icon': {
      color: theme.colors.red[5],
      marginRight: theme.spacing.xs,
      cursor: 'pointer',
      width: '20 !important',
      height: 20,
      minWidth: 20,
    },

    '.sidebar-section-title': {
      fontSize: theme.fontSizes.xs,
      color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[8],
      fontWeight: 'bold',
      marginLeft: theme.spacing.sm,
      marginTop: theme.spacing.md,
    },
  };
};
