import { sandBoxAxios } from '../config/axios.config';
import {
  CreateSandboxApiData,
  SandboxCreationFormData,
  SandboxPropertyDefinitionValue,
  SandboxPropertyValue,
  SandboxPropertyValueValidation,
  SandboxSummaryDto,
  UserEmployee,
  UserModle,
} from '../models';

const webhookService = {
  createSandbox: async (payload: CreateSandboxApiData): Promise<SandboxSummaryDto> => {
    const { data } = await sandBoxAxios.put(`/create/${payload.partnerId}`, payload.creationData);
    return data;
  },
  getSandboxStatus: async (partnerId: string): Promise<SandboxSummaryDto[]> => {
    const { data } = await sandBoxAxios.get(`/${partnerId}`);
    return data;
  },
  getSandboxMenuData: async (organisationId: string): Promise<SandboxCreationFormData> => {
    const { data } = await sandBoxAxios.get(`/menu-data/${organisationId}`);
    return data;
  },
  createUser: async (user: UserModle): Promise<UserEmployee> => {
    const { data } = await sandBoxAxios.put(`/create-user/${user.organizationId}`, user);
    return data;
  },
  deleteSandbox: async (payload: {
    organizationId: string;
    sandBoxId: string;
  }): Promise<UserEmployee> => {
    const { data } = await sandBoxAxios.put(`/delete`, payload);
    return data;
  },
  verifyEmail: async (email: string): Promise<any> => {
    const { data } = await sandBoxAxios.get(`/partner/byEmail/${email}`);
    return data;
  },
  getAllSandboxPropertyDefinitionValue: async (
    organizationId: string,
  ): Promise<SandboxPropertyDefinitionValue[]> => {
    const { data } = await sandBoxAxios.get(`/property-definition-value/${organizationId}`);
    return data;
  },
  updatePropertyValue: async (payload: {
    organizationId: string;
    propertyValues: SandboxPropertyValue[];
  }): Promise<SandboxPropertyValueValidation[]> => {
    const { data } = await sandBoxAxios.put(
      `/update-property-value/${payload.organizationId}`,
      payload.propertyValues,
    );
    return data;
  },
};

export default webhookService;
