import { useSelector } from 'react-redux';
import { RootState } from '../redux/common';
import { OrganizationState } from '../redux/reducers';
import { useCallback } from 'react';

const useGetValidCountryList = () => {
  const { countries } = useSelector<RootState, OrganizationState>((state: RootState) => {
    return state.organization;
  });

  const getCountryList = useCallback(() => {
    const validIdList: string[] = [
      '5f7b2c528088545d9602408c',
      '5f7c492313b5c5503cbfe63e',
      '5f7c492313b5c5503cbfe63d',
      '5fccaa64a699360b32df4065',
      '609cfc1356a70e037a9ea343',
      '609cfc4756a70e037a9ea344',
      '609cfcc656a70e037a9ea345',
      '609cfd1156a70e037a9ea346',
      '609cfd6956a70e037a9ea347',
      '609cfdc856a70e037a9ea348',
      '609cfe0a56a70e037a9ea349',
      '609cfe7056a70e037a9ea34a',
      '60d2c5ab99be6da3431bd7ae',
      '60d317ec99be6da343c429cb',
      '60d317fd99be6da343c47129',
      '6178f2a9739227136c75329a',
      '61acdf35787b4f5d5c7caca0',
      '61c970c2036b9f3731064eff',
      '61c974cd036b9f3731064f03',
      '61c9774c036b9f3731064f0d',
      '61c9786d036b9f3731064f10',
      '61c979bb036b9f3731064f15',
      '61c97bd9036b9f3731064f1b',
      '61c97d2c036b9f3731064f1e',
      '620b77b830956808f54eb9a5',
      '624307546b0284713075f66a',
      '62f65428bd460d719d1bb038',
      '63345ff1ebe24522fba3fbac',
    ];

    if (!countries) return [];

    return (
      countries
        ?.filter((country) => {
          if (validIdList.includes(country?.id)) {
            return country;
          }
        })
        ?.map((country) => {
          return {
            value: country?.id ?? '',
            label: country?.name ?? '',
          };
        }) ?? []
    );
  }, [countries]);

  return [getCountryList];
};

export default useGetValidCountryList;
