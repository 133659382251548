import { Box, Button, Checkbox, Loader, Modal, Paper, useMantineTheme } from '@mantine/core';
import { FC } from 'react';
import { Document, Page } from 'react-pdf';
import { agreementModalContent, agreementModalStyles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/common';
import { IntegrationState } from '../../../redux/reducers';
import { integrationActionCreator } from '../../../redux/actions';
import { pdf } from '../../../assets/pdf';

type AgreementModalProps = {
  id: string;
  opened: boolean;
  open: () => void;
  close: () => void;
  title: string;
  onAgree: () => void;
  readOnly: boolean;
};

const AgreementModal: FC<AgreementModalProps> = ({
  opened,
  close,
  title,
  onAgree,
  readOnly = false,
}) => {
  const theme = useMantineTheme();
  const dispatch = useDispatch();

  const { pdfPageCount, isAgreeToTermsAndConditions } = useSelector<RootState, IntegrationState>(
    (state: RootState) => {
      return state.integration;
    },
  );

  const onLoadSuccess = ({ numPages }: { numPages: number }) => {
    dispatch(integrationActionCreator.changePdfCount(numPages));
  };

  return (
    <Modal
      opened={opened}
      onClose={() => {
        dispatch(integrationActionCreator.changePdfCount(0));
        dispatch(integrationActionCreator.changeIsAgreeToCoditions(false));
        close();
      }}
      title={`${title} Terms and Conditions`}
      sx={agreementModalStyles(theme)}
      size={'650px'}
    >
      <Box sx={agreementModalContent(theme)}>
        <Document
          file={pdf}
          onLoadError={(error) => console.log('error', error)}
          onLoadSuccess={onLoadSuccess}
          className={'pdf-document'}
          loading={<Loader />}
        >
          {Array.from(new Array(pdfPageCount), (el, index) => (
            <Paper shadow="xs" withBorder>
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            </Paper>
          ))}
        </Document>
        {!readOnly && (
          <Paper shadow="md" className="footer">
            <Checkbox
              label={'I agree to the terms and conditions.'}
              onChange={() => {
                dispatch(
                  integrationActionCreator.changeIsAgreeToCoditions(!isAgreeToTermsAndConditions),
                );
              }}
              checked={isAgreeToTermsAndConditions}
            />
            <Button
              variant="outline"
              onClick={() => {
                dispatch(integrationActionCreator.changePdfCount(0));
                dispatch(integrationActionCreator.changeIsAgreeToCoditions(false));
                onAgree();
                close();
              }}
              disabled={!isAgreeToTermsAndConditions}
            >
              {`Create ${title}`}
            </Button>
          </Paper>
        )}
      </Box>
    </Modal>
  );
};

export default AgreementModal;
