import {
  Capability,
  DeliveryCapabilityIdType,
  IntegrationCapability,
  IntegrationCapabilityDefinition,
  IntegrationCapabilityRow,
  IntegrationDefinition,
  OrderCapabilityIdType,
  PosCapabilityIdType,
} from '../../../../models';

export const validateFormData = (
  formData: Record<string, string>,
  integrationCapabilityDefinitions: IntegrationCapabilityDefinition[],
): boolean => {
  const validateCapability = (
    capability: IntegrationCapabilityDefinition,
    isParentMandatory: boolean,
  ): boolean => {
    if (isParentMandatory) {
      // if parent is mandatory, at least 1 child should be true
      const isParentTrue = formData[capability.id] === 'true';
      const areChildrenTrue =
        capability.childCapabilities &&
        (capability.childCapabilities.length < 2 ||
          capability.childCapabilities.some((child) => validateCapability(child, true)));
      return isParentTrue && (areChildrenTrue || !capability.childCapabilities);
    } else {
      if (!capability.childCapabilities) {
        return true;
      } else {
        const areChildrenTrue = capability.childCapabilities.some((child) =>
          validateCapability(child, false),
        );
        return areChildrenTrue || !capability.childCapabilities;
      }
    }
  };

  for (const capability of integrationCapabilityDefinitions) {
    if (!validateCapability(capability, capability.mandatory)) {
      return false;
    }
  }

  // All validations passed
  return true;
};

export const hasMandatoryChildren = (item: IntegrationCapabilityDefinition): boolean => {
  return item.childCapabilities && item.childCapabilities.some((child) => child.mandatory === true);
};

export const fillMissingValues = (
  values: Record<string, string>,
  item: IntegrationCapabilityDefinition,
) => {
  if (item.mandatory) {
    if (values[item.id]) {
      return;
    }
    if (!item.childCapabilities) {
      values[item.id] = 'true';
    } else {
      const hasMandatoryChildren = item.childCapabilities.some((child) => child.mandatory);
      if (!hasMandatoryChildren) {
        const firstChild = item.childCapabilities[0];
        const hasCheckedChildren = item.childCapabilities.some(
          (child) => values[child.id] === 'true',
        );
        values[item.id] = 'true';
        if (!hasCheckedChildren) {
          values[firstChild.id] = 'true';
        }
        fillMissingValues(values, firstChild);
      } else {
        values[item.id] = 'true';
        item.childCapabilities.forEach((child) => {
          if (child.mandatory) {
            fillMissingValues(values, child);
          }
        });
      }
    }
  } else {
    if (!values?.[item.id] || values?.[item.id] === 'null') {
      values[item.id] = 'false';
    }
  }
};

export const createInitialData = (
  capabilityValues: IntegrationCapability,
  integrationDefinition: IntegrationDefinition,
) => {
  const initialValues: Record<
    OrderCapabilityIdType | DeliveryCapabilityIdType | PosCapabilityIdType,
    string
  > =
    capabilityValues?.integrationCapabilityRows?.reduce((acc, capability) => {
      acc[capability.capabilityId] = capability.value;
      return acc;
    }, {} as Record<OrderCapabilityIdType | DeliveryCapabilityIdType | PosCapabilityIdType, string>) ??
    {};

  integrationDefinition.capabilities.forEach((integrationItem) => {
    fillMissingValues(initialValues, integrationItem);
  });
  return initialValues;
};

export const createCapabilityPayload = (
  formData: Record<string, string>,
  integrationId: string,
  initialCapabilityData: Capability,
): Capability => {
  const currentIntegrationPayload = (formData: Record<string, string>) => {
    return Object.entries(formData).map(([capabilityId, value]) => ({
      capabilityId,
      value,
    }));
  };

  const integrationInitialCapabilities =
    initialCapabilityData?.integrationCapabilities?.filter(
      (capability) => capability?.integrationId?.id !== integrationId,
    ) ?? [];

  return {
    ...initialCapabilityData,
    integrationCapabilities: [
      ...integrationInitialCapabilities,
      {
        integrationId: { id: integrationId },
        integrationCapabilityRows: currentIntegrationPayload(
          formData,
        ) as IntegrationCapabilityRow[],
      },
    ],
  };
};
