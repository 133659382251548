import { FC } from 'react';
import { Box, Flex, Button } from '@mantine/core';
import { viewNdaCardStyles } from './style';
import { useNavigate } from 'react-router-dom';

const ViewNdaCard: FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box sx={viewNdaCardStyles()}>
        <Flex className="nda-card-content">
          <Button className="view-nda-button" variant="outline" onClick={() => navigate('/nda')}>
            View Your NDA
          </Button>
        </Flex>
      </Box>
    </>
  );
};

export default ViewNdaCard;
