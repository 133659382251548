import {
  Button,
  Divider,
  Flex,
  Grid,
  Group,
  LoadingOverlay,
  Paper,
  TextInput,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconHelpCircle, IconWebhook } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { object as validateObject, string as validateString } from 'yup';
import {
  AuthFormSave,
  AuthTypes,
  IntegrationActivationStatus,
  WebHookDefinition,
  WebHookId,
  WebHookValue,
} from '../../../models';
import { webhookActionCreator } from '../../../redux/actions/webhook';
import { RootState } from '../../../redux/common';
import { AuthenticationState, IntegrationState } from '../../../redux/reducers';
import { webhookService } from '../../../service';
import { convertEnumToString, endPointReplacer, urlValidationRegex } from '../../../utils/string';
import { webhookSectionStyles } from './styles';
import WebHookAuthenticationSelect from '../../../components/AuthenticationFields/AuthenticationSelect';
import documentationService from '../../../service/documentation.service';

type WebhookSectionProps = {
  integrationId: string;
};

const WebhookSection: FC<WebhookSectionProps> = ({ integrationId }) => {
  const theme = useMantineTheme();
  const dispatch = useDispatch();

  const { integrationDefinitions, activatedIntegrations } = useSelector<
    RootState,
    IntegrationState
  >((state: RootState) => {
    return state.integration;
  });

  const { userData } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });

  const { data: webhookDefinitions, isLoading: isLoadingWebhookDefinitions } = useQuery({
    queryKey: [`get-webHook-definitions-${integrationId}`, integrationId],
    queryFn: () => webhookService.getWebHookDefinitions(integrationId),
    onSuccess: (data) => {
      dispatch(webhookActionCreator.changeWebhookDefinitions(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const { data: webhookDefinitionsValues, isLoading: isLoadingWebhookValues } = useQuery({
    queryKey: [`get-webHook-values-${integrationId}`, integrationId, userData.organizationId],
    queryFn: () => webhookService.getWebHookValues(integrationId, userData.organizationId),
    onSuccess: (data) => {
      dispatch(webhookActionCreator.changeWebhookvalues(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const documentationAuthRequest = useMutation({
    mutationFn: (payload: { email: string; url: string }) => {
      return documentationService.getDocumentationAuth(payload.email);
    },
    onSuccess: (data, initialValues) => {
      if (!!data && data?.authToken) {
        window.open(`${initialValues.url}?auth_token=${data.authToken}`);
        return;
      }
    },
    onError: (error: any) => {
      notifications.show({
        title: 'Something went wrong',
        message: JSON.stringify(error) ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const getInitialValues = useCallback(() => {
    let initialValues: any = {};
    webhookDefinitions?.forEach((definition) => {
      initialValues[definition.id.id] =
        webhookDefinitionsValues?.find(
          (definitionValue) =>
            definitionValue.integration.id === definition.integration.id &&
            definitionValue.webHookId.id === definition.id.id,
        )?.value ?? '';
    });
    return initialValues;
  }, [webhookDefinitions, webhookDefinitionsValues]);

  const getInitialValuesOfSecretKeys = useCallback(() => {
    let initialValues: any = {};
    webhookDefinitions?.forEach((definition) => {
      initialValues[definition.id.id] =
        webhookDefinitionsValues?.find(
          (definitionValue) =>
            definitionValue.integration.id === definition.integration.id &&
            definitionValue.webHookId.id === definition.id.id,
        )?.secretKey ?? '';
    });
    return initialValues;
  }, [webhookDefinitions, webhookDefinitionsValues]);

  const [secretKeys, setSecretKeys] = useState<{ [key: string]: string }>(
    getInitialValuesOfSecretKeys(),
  );

  const generateValidateSchema = useCallback(() => {
    const validationObj: any = {};
    const initialValues = getInitialValues();
    for (const key in initialValues) {
      validationObj[key] = validateString()
        .trim()
        .required(`${convertEnumToString(key)} is required`)
        .matches(urlValidationRegex, 'Must be a valid url')
        .url('Must be a valid url');
    }
    return validateObject().shape(validationObj);
  }, [getInitialValues]);

  const { values, setValues, setFieldValue, handleBlur, touched, errors } = useFormik({
    initialValues: getInitialValues(),
    onSubmit: () => {},
    validationSchema: generateValidateSchema(),
  });

  useEffect(() => {
    setValues(getInitialValues());
    setSecretKeys(getInitialValuesOfSecretKeys());
  }, [getInitialValues, setValues, getInitialValuesOfSecretKeys]);

  const saveWebhookUrls = useMutation({
    mutationFn: (payload: { integrationId: string; partnerId: string; values: WebHookValue[] }) => {
      return webhookService.persistWebHookValues(payload);
    },
    onSuccess: () => {
      notifications.show({
        title: 'Successfully Saved',
        message: '',
        color: 'green',
      });
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const getIntegrationData = () =>
    integrationDefinitions.find(
      (integrationDefinition) => integrationDefinition.id.id === integrationId,
    );

  const getWebhookValues = (webHookId: WebHookId, customAuthentication?: AuthFormSave) => {
    let data: WebHookValue[] = [];
    for (const key in values) {
      if (key === webHookId.id) {
        const obj = {
          integration: { id: integrationId },
          webHookId: { id: key.toString() },
          value: values[key],
          secretKey: secretKeys[key],
          authType: AuthTypes.X_API_KEY,
          basicAuthValue: initialWebhookValues?.[key]?.basicAuthValue ?? null,
          oauthValue: initialWebhookValues?.[key]?.oauthValue ?? null,
          specialApiKeyValue: initialWebhookValues?.[key]?.specialApiKeyValue ?? null,
          ...(customAuthentication ?? {}),
        };
        data = [obj, ...data];
      }
    }
    return data;
  };

  const isValid = (webhookDefinition: WebHookDefinition) => {
    if (webhookDefinition.secretKeyRequired && secretKeys[webhookDefinition.id.id]?.length <= 0)
      return false;
    if (errors[webhookDefinition.id.id]) return false;
    return true;
  };

  const onClickSave = (webHookId: WebHookId, customAuthenticationValues?: AuthFormSave) => {
    if (userData.organizationId) {
      const payload = {
        integrationId: integrationId,
        partnerId: userData.organizationId,
        values: getWebhookValues(webHookId, customAuthenticationValues),
      };
      saveWebhookUrls.mutate(payload);
    }
  };

  const getEndpointUrl = (baseUrl: string, endPoint: string) => {
    if (endPoint?.length < 20) {
      return `${baseUrl?.slice(0, 80)}${baseUrl?.length < 80 ? '' : '...'}${endPoint}`;
    }
    if (endPoint?.length < 30) {
      return `${baseUrl?.slice(0, 70)}${baseUrl?.length < 70 ? '' : '...'}${endPoint}`;
    }
    if (endPoint?.length < 40) {
      return `${baseUrl?.slice(0, 60)}${baseUrl?.length < 60 ? '' : '...'}${endPoint}`;
    }
    if (endPoint?.length > 40) {
      return `${baseUrl?.slice(0, 50)}${baseUrl?.length < 50 ? '' : '...'}${endPoint}`;
    }
    return null;
  };

  const activeData = useMemo(
    () =>
      activatedIntegrations.find((activeData) => {
        let status = false;
        try {
          status = activeData.integration.id === integrationId;
        } catch (e) {
          console.error(e, activeData);
        }
        return status;
      }),
    [activatedIntegrations, integrationId],
  );

  const getEndpoints = useCallback(
    (definition: WebHookDefinition) => {
      if (!definition.endpoints || definition.endpoints.endpoints.length <= 0) return null;
      return (
        <Grid className="endpoints">
          {definition.endpoints.endpoints.map(
            ({ description, endpoint, documentationUrl }, index) => {
              return (
                <>
                  <Grid.Col span={2} className="title">
                    {index === 0 && 'EndPoints'}
                  </Grid.Col>
                  <Grid.Col span={2} className="description">
                    {description}
                  </Grid.Col>
                  <Grid.Col span={6} className="endpoint">
                    {getEndpointUrl(
                      values[definition.id.id],
                      endPointReplacer(endpoint, definition.endpoints.urlResolvers),
                    )}
                  </Grid.Col>
                  <Grid.Col span={1} className="icon">
                    <IconHelpCircle
                      size={18}
                      onClick={() => {
                        documentationAuthRequest.mutate({
                          email: userData.email,
                          url: documentationUrl,
                        });
                      }}
                    />
                  </Grid.Col>
                </>
              );
            },
          )}
        </Grid>
      );
    },
    // eslint-disable-next-line
    [webhookDefinitions, values],
  );

  const initialWebhookValues = useMemo(() => {
    const defaultWebhookValues: Record<string, WebHookValue | undefined> = {};
    webhookDefinitions?.forEach((definition) => {
      defaultWebhookValues[definition.id.id] = webhookDefinitionsValues?.find(
        (definitionValue) =>
          definitionValue.integration.id === definition.integration.id &&
          definitionValue.webHookId.id === definition.id.id,
      );
    });
    return defaultWebhookValues;
  }, [webhookDefinitions, webhookDefinitionsValues]);

  const isDisableFields = useMemo(() => {
    return activeData?.status.includes(IntegrationActivationStatus.GO_LIVE_APPROVED);
  }, [activeData]);

  return (
    <Paper
      id={integrationId}
      shadow="xs"
      className="webhook-urls-paper"
      sx={webhookSectionStyles(theme)}
    >
      <LoadingOverlay
        visible={
          isLoadingWebhookValues ||
          isLoadingWebhookDefinitions ||
          saveWebhookUrls.isLoading ||
          documentationAuthRequest.isLoading
        }
      />
      <Group position="center">
        <Title
          order={3}
          className="webhook-urls-title"
          data-cy={`webhook-urls-title-${getIntegrationData()?.name}`}
        >
          {getIntegrationData()?.name}
        </Title>
        <Divider orientation="vertical" />
        <div className="webhook-urls-api-container">
          {/* <Title order={3}>test:</Title> */}
          {webhookDefinitions?.map((definition, index) => {
            return (
              <>
                <div className="title-main">{definition.name}</div>
                <Flex direction="row" align="end">
                  <Flex direction="column" className="column-flex">
                    <Grid>
                      <Grid.Col span={11}>
                        <TextInput
                          label={definition.description}
                          placeholder="Sandbox webhook URL"
                          icon={<IconWebhook />}
                          className="webhook-urls-text-input"
                          value={values[definition.id.id] ?? ''}
                          onChange={(event) =>
                            setFieldValue(`${definition.id.id}`, event.target.value)
                          }
                          data-cy={`webhook-urls-text-input-${definition.id.id}`}
                          onBlur={handleBlur}
                          name={definition.id.id}
                          //@ts-ignore
                          error={touched[definition.id.id] && errors[definition.id.id]}
                          disabled={isDisableFields}
                        />
                      </Grid.Col>
                      {!definition.specialApiKey ? (
                        <>
                          <Grid.Col span={11}>
                            <TextInput
                              placeholder="Api Key"
                              icon={<IconWebhook />}
                              className="webhook-urls-text-input api-key"
                              value={secretKeys[definition.id.id] ?? ''}
                              onChange={(event) =>
                                setSecretKeys((old) => {
                                  return { ...old, [definition.id.id]: event.target.value };
                                })
                              }
                              data-cy={`webhook-urls-text-input-${definition.id.id}`}
                              disabled={isDisableFields}
                            />
                          </Grid.Col>
                          <Grid.Col span={1} className="button-wrapper">
                            <Button
                              className="webhooks-button"
                              disabled={!isValid(definition)}
                              onClick={() => onClickSave(definition.id)}
                              data-cy={`webhook-urls-save-button-${definition.id.id}`}
                            >
                              Save
                            </Button>
                          </Grid.Col>
                        </>
                      ) : null}
                      <Grid.Col span={11}>{getEndpoints(definition)}</Grid.Col>
                      {definition.specialApiKey ? (
                        <Grid.Col span={12}>
                          <WebHookAuthenticationSelect
                            webHookValue={initialWebhookValues?.[definition.id.id]}
                            onSave={(values) => {
                              onClickSave(definition.id, values);
                            }}
                            isParentValid={isValid(definition)}
                            isParentDisabled={isDisableFields}
                          />
                        </Grid.Col>
                      ) : null}
                    </Grid>
                  </Flex>
                </Flex>
              </>
            );
          })}
        </div>
      </Group>
    </Paper>
  );
};

export default WebhookSection;
