import { notifications } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/common';
import { AuthenticationState, IntegrationState } from '../redux/reducers';
import { webhookGoLiveService, webhookService } from '../service';

const useIsProdWebhooksAdded = (integrationId: string | undefined) => {
  const { activatedIntegrations } = useSelector<RootState, IntegrationState>((state: RootState) => {
    return state.integration;
  });

  const { userData } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });

  const [isAllWebhooksAddedProd, setIsAllWebhooksAddedProd] = useState<boolean>(false);
  const [webHoookDefinitionsAndValues, setWebHoookDefinitionsAndValues] = useState<{
    [key: string]: {
      webhhokDefinitions: any[];
      webhookValues: any[];
    };
  }>();

  useEffect(() => {
    if (!activatedIntegrations || activatedIntegrations?.length <= 0 || !userData.organizationId)
      return;

    activatedIntegrations
      .filter((activatedIntegration) => activatedIntegration.integration.id === integrationId)
      .forEach((integration) => {
        getWebhookDefinitions.mutate(integration.integration.id);
      });
    // eslint-disable-next-line
  }, [activatedIntegrations, userData.organizationId, integrationId]);

  const getWebhookDefinitions = useMutation({
    mutationFn: (integrationId: string) => {
      return webhookService.getWebHookDefinitions(integrationId);
    },
    onSuccess: (data, integrationId) => {
      setWebHoookDefinitionsAndValues((currentState) => {
        return {
          ...currentState,
          [integrationId]: {
            webhhokDefinitions: data,
            webhookValues: [],
          },
        };
      });
      getWebhookValues.mutate(integrationId);
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const getWebhookValues = useMutation({
    mutationFn: (integrationId: string) => {
      return webhookGoLiveService.getWebHookValues(integrationId, userData.organizationId);
    },
    onSuccess: (data, integrationId) => {
      if (
        webHoookDefinitionsAndValues?.[integrationId]?.webhhokDefinitions?.filter(
          (definition) => definition.productionRequired,
        ).length === data.length
      ) {
        return setIsAllWebhooksAddedProd(true);
      }
      if (isAllWebhooksAddedProd) setIsAllWebhooksAddedProd(false);
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  return [isAllWebhooksAddedProd, getWebhookDefinitions.isLoading, getWebhookValues.isLoading];
};

export default useIsProdWebhooksAdded;
