import { MantineTheme, Sx } from '@mantine/core';

export const manageApiAccessStyles = (theme: MantineTheme): Sx => {
  return {
    '.content': {
      background: '#fff',
      padding: theme.spacing.xl,
    },
    '.main-container': {
      width: '100%',
      marginTop: '12px',
    },
    '.card-content': {
      height: '100%',
    },
  };
};
