import { Sx } from '@mantine/core';

export const viewNdaCardStyles = (): Sx => {
  return {
    '.nda-card-content': {
      padding: '24px',
      background: '#ffff',
    },
    '.view-nda-button': {
      width: '100%',
    },
  };
};
