import { Divider, Flex, Stepper, Text, useMantineTheme } from '@mantine/core';
import { useState } from 'react';
import PageLayout from '../../components/PageLayout/PageLayout';
import StyledStepper from './StyledStepper/StyledStepper';
import { onboardingStyles } from './style';

import {
  IconArrowRight,
  IconBriefcaseOff,
  IconCheck,
  IconLockOff,
  IconPlug,
} from '@tabler/icons-react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { RootState } from '../../redux/common';
import { AuthenticationState } from '../../redux/reducers';
import { welcomePageImage } from '../../assets/images';

const Onboarding = () => {
  const theme = useMantineTheme();
  const { userData } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });
  const navigate = useNavigate();
  const [step] = useState(userData.organizationId === null ? 0 : 1);

  return (
    <PageLayout id={'onboarding-page'} sx={onboardingStyles(theme)}>
      <Flex className="onboarding-container" direction={'column'}>
        <Flex className="onboarding-top-container">
          <Flex className="onboarding-definition-container" direction={'column'}>
            <StyledStepper active={step} className="onboarding-stepper">
              <Stepper.Step withIcon={false} />
              <Stepper.Step withIcon={false} />
              <Stepper.Step withIcon={false} />
            </StyledStepper>
            <span className="onboarding-step-number">{step}/2</span>
            <Text className="onboarding-welcome-text">Welcome to the Developer Portal</Text>
            <Text className="onboarding-main-definition">
              Carry out API testing, keep track of integrations and find support for building
              connections.
            </Text>
            <Text className="onboarding-additional-definition">
              To get started, you'll need to set up your organisation and activate your chosen API
              suite.
            </Text>
          </Flex>
          <img
            src={welcomePageImage}
            alt={'welcomePageImage'}
            className="onboarding-no-setup-image"
          />
        </Flex>
        <Flex direction={'column'}>
          <Text className="onboarding-get-started-text">Get started checklist</Text>
          <Flex
            className={step === 1 ? 'onboarding-setup-completed' : 'onboarding-redirect-card'}
            align={'center'}
            onClick={step === 0 ? () => navigate('/setup-organization') : () => {}}
          >
            {step === 0 ? (
              <IconBriefcaseOff size={'2rem'} className="onboarding-redirect-icon" />
            ) : (
              <IconCheck size={'2rem'} className="onboarding-redirect-icon" color="green" />
            )}
            <Flex direction={'column'}>
              <Text>Set up your organisation</Text>
              <Text>
                Start by creating an organisation, or join an existing one if you have an
                invitation.
              </Text>
            </Flex>
            {step === 0 && (
              <IconArrowRight size={'2rem'} className="onboarding-redirect-status-icon" />
            )}
          </Flex>
          <Divider />
          <Flex
            className={step === 0 ? 'onboarding-setup-completed' : 'onboarding-redirect-card'}
            align={'center'}
            onClick={step === 1 ? () => navigate('/manage-api-access') : () => {}}
          >
            <IconPlug size={'2rem'} className="onboarding-redirect-icon" />
            <Flex direction={'column'}>
              <Text>Choose an API Suite</Text>
              <Text>
                Before you can fully access our APIs, you'll need to select an API suite, submit a
                contract, and wait for it to be reviewed.
              </Text>
            </Flex>
            {step === 0 ? (
              <IconLockOff size={'2rem'} className="onboarding-redirect-status-icon" />
            ) : (
              <IconArrowRight size={'2rem'} className="onboarding-redirect-status-icon" />
            )}
          </Flex>
        </Flex>
      </Flex>
    </PageLayout>
  );
};

export default Onboarding;
