import {
  Box,
  Button,
  Flex,
  List,
  LoadingOverlay,
  Text,
  ThemeIcon,
  useMantineTheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconArrowNarrowRight, IconCircleCheck } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { pendingIconActiveStatue } from '../../../assets/images';
import Card, { SubStatusElement } from '../../../components/Card/Card';
import {
  ActivateIntegrationPayload,
  IntegrationActivationStatus,
  IntegrationActivationStatusWithTime,
  capabilitiesDisabledStatuses,
} from '../../../models/integration';
import {
  authenticationActionCreator,
  integrationActionCreator,
  testFrameworkActionCreator,
} from '../../../redux/actions';
import { RootState } from '../../../redux/common';
import { AuthenticationState, IntegrationState } from '../../../redux/reducers';
import { testFrameworkService } from '../../../service';
import integratioService from '../../../service/integration.service';
import { convertEnumToString } from '../../../utils/string';
import AgreementModal from '../AgreementModal/AgreementModal';
import { partnerPlatformCardStyles } from './style';
import { convertISOToDDMMYYYY } from '../../../utils/date';

type APIAccessCardProps = {
  id: string;
  availableApiSuiteElements: SubStatusElement[];
  isCustomizedParamsDisabled: boolean;
};

const APIAccessCardCard: FC<APIAccessCardProps> = ({ id, availableApiSuiteElements, isCustomizedParamsDisabled }) => {
  const theme = useMantineTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [opened, { open, close }] = useDisclosure();

  const { integrationDefinitions, activatedIntegrations } = useSelector<
    RootState,
    IntegrationState
  >((state: RootState) => {
    return state.integration;
  });
  const { userData } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });

  // const { testSuiteDefinitions } = useSelector<RootState, TestFrameworkState>(
  //   (state: RootState) => {
  //     return state.testFramework;
  //   },
  // );

  const getTestSuitesByIntegration = useMutation({
    mutationFn: (integartion: string) => {
      return testFrameworkService.getTestSuitesByIntegration(integartion);
    },
    onSuccess: (data) => {
      dispatch(testFrameworkActionCreator.changeTestSuiteDefinitionData(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const activateIntegration = useMutation({
    mutationFn: (payload: ActivateIntegrationPayload) => {
      return integratioService.activateIntegration(payload);
    },
    onSuccess: (data) => {
      getTestSuitesByIntegration.mutate(data.integration.id);
      dispatch(integrationActionCreator.changeUpdateActivatedIntegration(data));
      dispatch(
        authenticationActionCreator.changeAuthData({
          isAuthenticated: true,
          userData: {
            ...userData,
            hasActiveApi: true,
          },
        }),
      );
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const definitionData = useMemo(
    () => integrationDefinitions.find((definitionData) => definitionData.id.id === id),
    [integrationDefinitions, id],
  );

  const isAlreadyActivate = useMemo(
    () =>
      activatedIntegrations.find((activated) => activated.integration.id === id) ? true : false,
    [activatedIntegrations, id],
  );

  const activeData = useMemo(
    () =>
      activatedIntegrations.find((activeData) => {
        let status = false;
        try {
          status = activeData.integration.id === id;
        } catch (e) {
          console.error(e, activeData);
        }
        return status;
      }),
    [activatedIntegrations, id],
  );

  const getPageLink = (key: IntegrationActivationStatus) => {
    switch (key) {
      case IntegrationActivationStatus.API_KEYS_CREATED:
        return navigate('/credentials');
      case IntegrationActivationStatus.WEB_HOOKS_REGISTERED:
        return navigate('/webhooks');
      case IntegrationActivationStatus.SANDBOX_ACTIVATED:
        return navigate('/sandbox-sites');
      // case IntegrationActivationStatus.TEST_CASES_PASSED:
      //   const suiteId = testSuiteDefinitions.find(({ integration }) => integration === id)?.id;
      //   if (suiteId) {
      //     dispatch(testFrameworkActionCreator.changeSelectedTestSuite(suiteId));
      //     dispatch(testFrameworkActionCreator.changeSelectedFlow(null));
      //     dispatch(testFrameworkActionCreator.changeSelectedSubFlow(null));
      //     dispatch(testFrameworkActionCreator.changeselectedTestCase(null));
      //     dispatch(testFrameworkActionCreator.changeTestCaseExecutionData(null));
      //     dispatch(testFrameworkActionCreator.changeTestSuiteIntanceData(null));
      //     return navigate(`/uat/${suiteId}/test-scenarios`);
      //   }
      //   return;
    }
  };

  const isCapabilitiesDisabled = (): boolean | undefined => {
    if (activeData && activeData.status && activeData.status.length > 0) {
      if (isTestCasesRejectedByAdmin(activeData.status)) {
        return false;
      } else {
        const filterActiveData = activeData.status
          .filter((status) => status !== IntegrationActivationStatus.API_KEYS_CREATED)
          .filter((status) => status !== IntegrationActivationStatus.CAPABILITIES_SUBMITTED);

        return (
          capabilitiesDisabledStatuses.includes(filterActiveData[filterActiveData.length - 1]) ||
          activeData.status.includes(IntegrationActivationStatus.TEST_CASES_SUBMITTED_FOR_APPROVAL)
        );
      }
    }
    return true;
  };

  const isTestCasesRejectedByAdmin = (statuses: IntegrationActivationStatus[]): boolean => {
    return (
      statuses[statuses.length - 1] === IntegrationActivationStatus.TEST_CASES_REJECTED_BY_ADMIN
    );
  };

  const getLastElement = (elements: IntegrationActivationStatusWithTime[], key: string): any => {
    return [...elements]?.reverse()?.find((statusUpdate) => statusUpdate.status === key);
  };

  const getStepper = useCallback(() => {
    if (!activeData) return null;

    return (
      <List spacing="xs" size="sm" center className="status-list">
        {Object.keys(IntegrationActivationStatus).filter(integrationActivationStatus => {
    if (integrationActivationStatus === 'CUSTOMIZED_PARAMETERS_CREATED' && isCustomizedParamsDisabled) {
        return false;
    }
    return true;
}).map((key) => {
          if (
            key === IntegrationActivationStatus.CONTRACT_REJECTED ||
            key === IntegrationActivationStatus.CAPABILITIES_SUBMITTED ||
            key === IntegrationActivationStatus.TEST_CASES_REJECTED_BY_ADMIN ||
            key === IntegrationActivationStatus.GO_LIVE_ON_HOLD
          )
            return null;

          const hasNavigate =
            key === IntegrationActivationStatus.API_KEYS_CREATED ||
            key === IntegrationActivationStatus.SANDBOX_ACTIVATED ||
            key === IntegrationActivationStatus.WEB_HOOKS_REGISTERED;

          const statusTime = getLastElement(activeData?.statusUpdates, key)?.time;
          return (
            <List.Item
              icon={
                <ThemeIcon color="#ffffff" size={24} radius="xl">
                  {activeData.status.includes(key as IntegrationActivationStatus) ? (
                    <IconCircleCheck color="green" size="1rem" />
                  ) : (
                    <img src={pendingIconActiveStatue} width={'18px'} height={'18px'} />
                  )}
                </ThemeIcon>
              }
              key={key}
            >
              <Text
                color={
                  activeData.status.includes(key as IntegrationActivationStatus) ? 'green' : 'blue'
                }
                className="text-description"
              >
                <Flex
                  wrap={'wrap'}
                  gap={'sm'}
                  justify="space-between"
                  className="activationStatus__text-container"
                >
                  <Box className="activationStatus__text-content">{convertEnumToString(key)}</Box>
                  {!!statusTime ? (
                    <Box
                      sx={{
                        ...(!hasNavigate ? { marginRight: '50px' } : {}),
                      }}
                      className="activationStatus__text-date"
                    >
                      {convertISOToDDMMYYYY(statusTime)}
                    </Box>
                  ) : null}
                </Flex>

                {hasNavigate && (
                  // || key === IntegrationActivationStatus.TEST_CASES_PASSED
                  <div
                    className="link-for-pages"
                    onClick={() => getPageLink(key as IntegrationActivationStatus)}
                  >
                    GO {<IconArrowNarrowRight size="1rem" />}
                  </div>
                )}
              </Text>
            </List.Item>
          );
        })}
      </List>
    );
    // eslint-disable-next-line
  }, [activeData]);

  const configureIntegration = useCallback(() => {
    navigate(`/manage-api-access/capabilities/${id.toLowerCase()}`);
    // eslint-disable-next-line
  }, [activeData, navigate, id]);

  return (
    <>
      <AgreementModal
        id={'test'}
        opened={opened}
        open={open}
        close={close}
        title={definitionData?.name ?? ''}
        onAgree={() => {
          activateIntegration.mutate({
            organizationId: userData.organizationId,
            integration: { id },
          });
        }}
        readOnly={isAlreadyActivate}
      />
      <Box sx={partnerPlatformCardStyles(theme)} className="card-content" id={id}>
        <LoadingOverlay
          visible={activateIntegration.isLoading || getTestSuitesByIntegration.isLoading}
        />
        <Card
          id="partner-platform-card"
          title={definitionData?.name ?? ''}
          definition={definitionData?.description ?? ''}
          statusTitle="Activation status:"
          statusContent={getStepper()}
          subStatusTitle="This Suite includes:"
          subStatusElements={availableApiSuiteElements}
          button={
            activeData ? (
              <div className="activated-buttons">
                <Button
                  className="configure-button"
                  data-cy={`configure-button-${definitionData?.name ?? ''}`}
                  variant="outline"
                  onClick={() => {
                    configureIntegration();
                  }}
                  fullWidth
                  disabled={isCapabilitiesDisabled()}
                >
                  Configure
                </Button>
              </div>
            ) : (
              <Button
                data-cy={`activate-button-${definitionData?.name ?? ''}`}
                onClick={() => {
                  dispatch(integrationActionCreator.changeActivatingFlow({ id }));
                  configureIntegration();
                }}
              >
                Activate
              </Button>
            )
          }
        />
      </Box>
    </>
  );
};

export default APIAccessCardCard;
