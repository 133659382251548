import { useSelector } from 'react-redux';
import PageLayout from '../../../components/PageLayout';
import { RootState } from '../../../redux/common';
import { IntegrationState } from '../../../redux/reducers';
import IntegrationSectionForProduction from './IntegrationSectionForProduction';
import { useDisclosure } from '@mantine/hooks';
import GeneratedApiKeyForProduction from '../GeneratedApiKeyForProduction';

const ProductionPageNew = () => {
  const { activatedIntegrations } = useSelector<RootState, IntegrationState>((state: RootState) => {
    return state.integration;
  });

  const [
    generatedApiKeyModalOpened,
    { open: openGeneratedApiKeyModal, close: closeGeneratedApiKeyModal },
  ] = useDisclosure(false);

  return (
    <PageLayout id={'api-credentials-production'}>
      <GeneratedApiKeyForProduction
        id="generated-api-key-modal"
        opened={generatedApiKeyModalOpened}
        close={() => {
          closeGeneratedApiKeyModal();
        }}
      />
      {activatedIntegrations.map(({ integration }) => {
        return (
          <IntegrationSectionForProduction
            integrationId={integration.id}
            key={integration.id}
            openGenerated={openGeneratedApiKeyModal}
          />
        );
      })}
    </PageLayout>
  );
};

export default ProductionPageNew;
