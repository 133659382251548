import PageHeader from '../../components/PageHeader/PageHeader';
import PageLayout from '../../components/PageLayout/PageLayout';
import { Grid, Box, useMantineTheme, LoadingOverlay } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { manageApiAccessStyles } from './style';
import PartnerPlatformCard from './APIAccessCard';
import { useQuery } from '@tanstack/react-query';
import integratioService from '../../service/integration.service';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/common';
import { IntegrationState } from '../../redux/reducers/integration';
import { integrationActionCreator } from '../../redux/actions';
import { AuthenticationState, OrganizationState } from '../../redux/reducers';
import { notifications } from '@mantine/notifications';
import ViewNdaCard from './ViewNdaCard/ViewNdaCard';
import ndaService from '../../service/nda.service';
import { ndaActionCreator } from '../../redux/actions/nda';
import { NdaStatus } from '../../models/nda';
import NDADocumentPage from './NDADocumentPage/NDADocumentPage';
import { IconArrowNarrowRight } from '@tabler/icons-react';
import { SubStatusElement } from '../../components/Card/Card';
import organizationService from '../../service/organizations.service';
import { organizationActionCreator } from '../../redux/actions/organization';
import { IntegrationId } from '../../models';

const ManageApiAccess = () => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const orderApiSubStatusElements: SubStatusElement[] = [
    {
      text: 'Orders API',
      iconText: 'GO',
      icon: <IconArrowNarrowRight size="1rem" />,
      iconLink: 'https://docs.grubtech.io/reference/createorder',
    },
    {
      text: 'Menu API',
      iconText: 'GO',
      icon: <IconArrowNarrowRight size="1rem" />,
      iconLink: 'https://docs.grubtech.io/reference/upload-menu',
    },
  ];
  const deliveryApiSubStatusElements: SubStatusElement[] = [
    {
      text: 'Delivery API',
      iconText: 'GO',
      icon: <IconArrowNarrowRight size="1rem" />,
      iconLink: 'https://docs.grubtech.io/docs/overview-delivery',
    },
  ];
  const posApiSubStatusElements: SubStatusElement[] = [
    {
      text: 'Menu API',
      iconText: 'GO',
      icon: <IconArrowNarrowRight size="1rem" />,
      iconLink: 'https://app.swaggerhub.com/apis-docs/GrubTech/menu-api/1.5.0',
    },
    {
      text: 'Orders API',
      iconText: 'GO',
      icon: <IconArrowNarrowRight size="1rem" />,
      iconLink:
        'https://app.swaggerhub.com/apis-docs/GrubTech/pos-webhook/1.5.0#/order/createOrder',
    },
    {
      text: 'Order Statuses Update API',
      iconText: 'GO',
      icon: <IconArrowNarrowRight size="1rem" />,
      iconLink:
        'https://app.swaggerhub.com/apis-docs/GrubTech/grubtech-order_status_update_api/1.0.0',
    },
  ];

  const getApiSubStatusElementsById = (id: string): SubStatusElement[] => {
    switch (id) {
      case 'ORDER':
        return orderApiSubStatusElements;
      case 'DELIVERY':
        return deliveryApiSubStatusElements;
      case 'POS':
        return posApiSubStatusElements;
      default:
        return [];
    }
  };

  const { integrationDefinitions } = useSelector<RootState, IntegrationState>(
    (state: RootState) => {
      return state.integration;
    },
  );
  const { userData } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });

  
  const { organization } = useSelector<RootState, OrganizationState>((state: RootState) => {
    return state.organization;
  });

  const { data: ndaStatus, isLoading: isLoadingNdaStatus } = useQuery({
    queryKey: ['get-nda-status', userData.organizationId],
    queryFn: () => ndaService.getNdaStatus(userData.organizationId),
    onSuccess: (data) => {
      dispatch(ndaActionCreator.changeNdaStatus(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const { isLoading: isLoadingIntegrationDefinitions } = useQuery({
    queryKey: ['get-integration-definitions', userData?.organizationId],
    queryFn: () => integratioService.getIntegrations(userData.organizationId),
    onSuccess: (data) => {
      dispatch(integrationActionCreator.changeIntegrationDefinition(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const { isLoading: isLoadingOrganization } = useQuery({
    queryKey: ['get-organization'],
    queryFn: () => organizationService.getOrganization(userData.organizationId || ''),
    onSuccess: (data) => {
      dispatch(organizationActionCreator.changeOrganization(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const { isLoading: isLoadingActivatedIntegrations } = useQuery({
    queryKey: ['get-activated-integration', userData.organizationId],
    queryFn: () => integratioService.getActivatedIntegrations(userData.organizationId),
    onSuccess: (data) => {
      dispatch(integrationActionCreator.changeActivatedIntegration(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const { data: integrationSummaries, isLoading: isLoadingIntegrationSummaries } = useQuery({
    queryKey: ['get-integration-summaries', userData.organizationId],
    queryFn: () => integratioService.getIntegrationParameterSummary(userData.organizationId),
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const getIsCustomizedDisabled = (integrationId: IntegrationId) => {
    if(!organization.enableParameterDefinitions) {
      return true;
    }
    const result = integrationSummaries?.find(integrationSummary => integrationSummary.integration.id === integrationId.id)?.isDisabled;
    if(result === undefined) {
      return true;
    }
    return result;
  }

  if (ndaStatus === NdaStatus.SUBMITTED || ndaStatus === NdaStatus.APPROVED) {
    return (
      <PageLayout
        id={'manage-api-access-page'}
        sx={manageApiAccessStyles(theme)}
        headerSection={
          <PageHeader
            id={'manage-api-access-page'}
            title={'Select an API suite'}
            backText={userData.hasActiveApi ? 'Dashboard' : ''}
            onClickBack={() => navigate('/')}
          />
        }
      >
        <LoadingOverlay
          visible={
            isLoadingActivatedIntegrations || isLoadingIntegrationDefinitions || isLoadingNdaStatus || isLoadingOrganization || isLoadingIntegrationSummaries
          }
        />
        <Box>
          <Grid className="main-container">
            {integrationDefinitions.map(({ id }) => (
              <Grid.Col span={3} key={id.id}>
                <PartnerPlatformCard
                  id={id.id}
                  key={id.id}
                  availableApiSuiteElements={getApiSubStatusElementsById(id.id)}
                  isCustomizedParamsDisabled={getIsCustomizedDisabled(id)}
                />
              </Grid.Col>
            ))}
            <Grid.Col span={3}>
              <ViewNdaCard />
            </Grid.Col>
          </Grid>
        </Box>
      </PageLayout>
    );
  }
  return <NDADocumentPage />;
};

export default ManageApiAccess;
