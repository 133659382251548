import { notifications } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/common';
import { TestFrameworkState } from '../redux/reducers';
import { testFrameworkService } from '../service';

const useIsUATStarts = (integrationId: string) => {
  const [isUatStarted, setIsUatStarted] = useState(false);

  const { testSuiteDefinitions } = useSelector<RootState, TestFrameworkState>(
    (state: RootState) => {
      return state.testFramework;
    },
  );

  useEffect(() => {
    const testSuiteDef = testSuiteDefinitions.find(
      (definition) => definition.integration === integrationId,
    );
    if (!testSuiteDef) return;

    getLatestInstanceOfSuite.mutate(testSuiteDef?.id);

    // eslint-disable-next-line
  }, [integrationId, testSuiteDefinitions]);

  const getLatestInstanceOfSuite = useMutation({
    mutationFn: (integartion: string) => {
      return testFrameworkService.getLatestInstanceOfSuite(integartion);
    },
    onSuccess: (data) => {
      if (data?.instanceId !== undefined) {
        setIsUatStarted(true);
      } else {
        setIsUatStarted(false);
      }
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  return [isUatStarted, getLatestInstanceOfSuite.isLoading];
};

export default useIsUATStarts;
