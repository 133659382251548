import { integrationAxios } from '../config/axios.config';
import { ActivateIntegrationPayload, Parameter, PartnerIntegrationStatus } from '../models/integration';

const integrationGoLiveService = {
  activateIntegration: async (
    payload: ActivateIntegrationPayload,
  ): Promise<PartnerIntegrationStatus> => {
    const { data } = await integrationAxios.put(
      `/go-live/activate/${payload.organizationId}/${payload.integration.id}`,
      payload,
    );
    return data;
  },

  getActivatedIntegrations: async (partnerId: string): Promise<PartnerIntegrationStatus[]> => {
    const { data } = await integrationAxios.get(`/go-live/activated/${partnerId}`);
    return data;
  },

  getIntegrationParameters: async (
    organizationId: string,
    integrationId: string | undefined,
  ): Promise<Parameter> => {
    const { data } = await integrationAxios.get(`/go-live/params/${organizationId}/${integrationId}`);
    return data;
  },

  createUpdateIntegrationParameters: async (
    organizationId: string,
    parameters: Parameter,
  ): Promise<Parameter> => {
    const { data } = await integrationAxios.put(
      `/go-live/params/${organizationId}`,
      parameters,
    );
    return data;
  },
};

export default integrationGoLiveService;
