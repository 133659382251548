import {
  Box,
  Center,
  Flex,
  LoadingOverlay,
  SegmentedControl,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconLock } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageHeader from '../../components/PageHeader/PageHeader';
import PageLayout from '../../components/PageLayout/PageLayout';
import { apiKeyActionCreator, integrationActionCreator } from '../../redux/actions';
import { RootState } from '../../redux/common';
import { APIKeyState, AuthenticationState, IntegrationState } from '../../redux/reducers';
import { apikeyService } from '../../service';
import integratioService from '../../service/integration.service';
import SandboxPageNew from './SanboxPageNew/SandboxPageNew';
import { apiCredentialsStyles } from './style';
import { EnvType } from '../../models';
import ProductionPageNew from './ProductionPageNew';
import organizationService from '../../service/organizations.service';
import { organizationActionCreator } from '../../redux/actions/organization';

const ApiCredentials = () => {
  const theme = useMantineTheme();
  const dispatch = useDispatch();

  const [selectedEnvType, setSelectedEnvType] = useState<EnvType>(EnvType.SANDBOX);

  const { userData } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });

  const { apiKeyList } = useSelector<RootState, APIKeyState>((state: RootState) => {
    return state.apiKey;
  });

  const { activatedIntegrations } = useSelector<RootState, IntegrationState>((state: RootState) => {
    return state.integration;
  });

  useEffect(() => {
    dispatch(apiKeyActionCreator.resetApiKeyList([]));
    dispatch(apiKeyActionCreator.resetApiSuiteDefinitionList([]));
    // eslint-disable-next-line
  }, []);

  const getApiSuites = useMutation({
    mutationFn: (integartionId: string) => {
      return apikeyService.getApiSuites(integartionId);
    },
    onSuccess: (data) => {
      dispatch(apiKeyActionCreator.changeApiSuiteDefinitions(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  useEffect(() => {
    if (activatedIntegrations && activatedIntegrations.length > 0) {
      activatedIntegrations.forEach((integration) => {
        getApiSuites.mutate(integration.integration.id);
      });
    }
    // eslint-disable-next-line
  }, [activatedIntegrations]);

  const { isLoading: isLoadingIntegrationDefinitions } = useQuery({
    queryKey: ['get-integration-definitions-api-credentials', userData?.organizationId],
    queryFn: () => integratioService.getIntegrations(userData.organizationId),
    onSuccess: (data) => {
      dispatch(integrationActionCreator.changeIntegrationDefinition(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
    enabled: activatedIntegrations.length > 0,
  });

  const { isLoading: isLoadingOrganization } = useQuery({
    queryKey: ['get-organization'],
    queryFn: () => organizationService.getOrganization(userData.organizationId || ''),
    onSuccess: (data) => {
      dispatch(organizationActionCreator.changeOrganization(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const { data: isProductionEnabled, isLoading: isLoadingCheckProductionEnabled } = useQuery({
    queryKey: [`check-is-production-enabled`, userData.organizationId],
    queryFn: () => apikeyService.checkIsProductionEnabled(userData.organizationId),
    onSuccess: (data) => {
      dispatch(apiKeyActionCreator.changeCheckIsProductionEnabledForApiKey(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const { isLoading: isLoadingActivatedIntegrations } = useQuery({
    queryKey: ['get-activated-integration-api-credentials', userData.organizationId],
    queryFn: () => integratioService.getActivatedIntegrations(userData.organizationId),
    onSuccess: (data) => {
      dispatch(integrationActionCreator.changeActivatedIntegration(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  return (
    <PageLayout
      id={'api-credentials-page'}
      sx={apiCredentialsStyles(theme)}
      headerSection={
        <PageHeader
          id={'api-credentials-page'}
          title={'API credentials'}
          description="Manage all your client IDs and client secrets here."
        />
      }
    >
      <LoadingOverlay
        visible={
          (apiKeyList.length > 0 && isLoadingIntegrationDefinitions) ||
          isLoadingActivatedIntegrations ||
          isLoadingCheckProductionEnabled || isLoadingOrganization
        }
      />

      <Box className="api-credentials-box">
        <Flex direction="row" justify="space-between" align="center" className="segment">
          <SegmentedControl
            value={selectedEnvType}
            onChange={(value) => setSelectedEnvType(EnvType[value as keyof typeof EnvType])}
            data={[
              {
                value: EnvType.SANDBOX,
                label: (
                  <Center>
                    <Text
                      size="sm"
                      weight={selectedEnvType === EnvType.SANDBOX ? 'bold' : 'normal'}
                      className={
                        selectedEnvType === EnvType.SANDBOX ? 'segmented-control-lbl-active' : ''
                      }
                      data-cy="segmented-control-sandbox"
                    >
                      For sandbox
                    </Text>
                  </Center>
                ),
              },
              {
                value: EnvType.PRODUCTION,
                label: (
                  <Center>
                    {!isProductionEnabled && <IconLock size={14} />}
                    <Text
                      size="sm"
                      ml={10}
                      data-cy="segmented-control-production"
                      weight={selectedEnvType === EnvType.PRODUCTION ? 'bold' : 'normal'}
                      className={
                        selectedEnvType === EnvType.PRODUCTION ? 'segmented-control-lbl-active' : ''
                      }
                    >
                      For production
                    </Text>
                  </Center>
                ),
                disabled: !isProductionEnabled,
              },
            ]}
            size="md"
          />
          {/* <Text>
          Quick help links:{' '}
          <CustomLink
            to="#"
            target="_blank"
            text="Learn more about webhooks"
            dataCy="link-learn-more-about-webhooks"
            inline
          />
        </Text> */}
        </Flex>
        {/* <SandboxPage /> */}
        {selectedEnvType === EnvType.SANDBOX ? <SandboxPageNew /> : <ProductionPageNew />}

        {/* <Tabs className="tabs" color="teal" defaultValue="urls" value={'sandbox'}>
          <Tabs.List>
            <Tabs.Tab value="sandbox" className="tab active-tab" data-cy="sandbox-tab">
              For sandbox
            </Tabs.Tab>
            <Tabs.Tab value="production" className="tab" data-cy="production-tab">
              <Flex>
                <IconLock className="lock-icon" size={14} />
                For production
              </Flex>
            </Tabs.Tab>
            <Text className="help-text">
              Quick help links:{' '}
              <CustomLink
                to="#"
                target="_blank"
                text="Authentication"
                dataCy="link-authentication"
                inline
              />
            </Text>
          </Tabs.List>

          <Tabs.Panel value="sandbox" pt="xs" className="env-tabs">
            {<SandboxPage />}
          </Tabs.Panel>

          <Tabs.Panel value="production" pt="xs">
            {<ProductionPage />}
          </Tabs.Panel>
        </Tabs> */}
      </Box>
    </PageLayout>
  );
};

export default ApiCredentials;
