export enum DeliveryCapabilityIdType {
  DEL_ASSIGN_DRIVER = 'DEL_ASSIGN_DRIVER',
  DEL_DRIVER_DETAIL_SHARING = 'DEL_DRIVER_DETAIL_SHARING',
  DEL_DRIVER_ETA = 'DEL_DRIVER_ETA',
  DEL_CANCELLATIONS = 'DEL_CANCELLATIONS',
  DEL_STATUS_UPDATES = 'DEL_STATUS_UPDATES',
  DEL_STATUS_UPDATES_DRIVER_ASSIGNED = 'DEL_STATUS_UPDATES_DRIVER_ASSIGNED',
  DEL_STATUS_UPDATES_DRIVER_ARRIVED = 'DEL_STATUS_UPDATES_DRIVER_ARRIVED',
  DEL_STATUS_UPDATES_DELIVERY_JOB_STARTED = 'DEL_STATUS_UPDATES_DELIVERY_JOB_STARTED',
  DEL_STATUS_UPDATES_DELIVERY_JOB_COMPLETED = 'DEL_STATUS_UPDATES_DELIVERY_JOB_COMPLETED',
}

export enum OrderCapabilityIdType {
  FA_ITEM_AVAILABILITY = 'FA_ITEM_AVAILABILITY',
  FA_SCHEDULED = 'FA_SCHEDULED',
  FA_RESTAURANT_DELIVERY = 'FA_RESTAURANT_DELIVERY',
  FA_DELIVERY = 'FA_DELIVERY',
  FA_DELIVERY_PICKUP_TIME = 'FA_DELIVERY_PICKUP_TIME',
  FA_DINE_IN = 'FA_DINE_IN',
  FA_PICKUP = 'FA_PICKUP',
  FA_ORDER_LEVEL_DISCOUNT = 'FA_ORDER_LEVEL_DISCOUNT',
  FA_ITEM_LEVEL_DISCOUNT = 'FA_ITEM_LEVEL_DISCOUNT',
  FA_MODIFIER_LEVEL_DISCOUNT = 'FA_MODIFIER_LEVEL_DISCOUNT',
  FA_ORDER_CANCELLATIONS = 'FA_ORDER_CANCELLATIONS',
  FA_PAYMENT_METHOD = 'FA_PAYMENT_METHOD',
  FA_ORDER_INS_ITEM_ORDER_LEVEL = 'FA_ORDER_INS_ITEM_ORDER_LEVEL',
  FA_MODIFIER_SUPPORT = 'FA_MODIFIER_SUPPORT',
  FA_ORDER_TYPES = 'FA_ORDER_TYPES',
  FA_PAYMENT_METHOD_PAID = 'FA_PAYMENT_METHOD_PAID',
  FA_PAYMENT_METHOD_UNPAID = 'FA_PAYMENT_METHOD_UNPAID',
}

export enum PosCapabilityIdType {
  POS_MENU = 'POS_MENU',
  POS_MENU_ITEM_AVAILABILITY = 'POS_MENU_ITEM_AVAILABILITY',
  POS_MENU_MODIFIERS = 'POS_MENU_MODIFIERS',
  POS_MENU_CAT_SERV_HRS = 'POS_MENU_CAT_SERV_HRS',
  POS_MENU_MAX_MIN_QTY_SUP = 'POS_MENU_MAX_MIN_QTY_SUP',
  POS_MENU_IMAGE_SUP = 'POS_MENU_IMAGE_SUP',
  POS_MENU_MULTI_LINGUAL_SUP = 'POS_MENU_MULTI_LINGUAL_SUP',
  POS_ORDERS = 'POS_ORDERS',
  POS_ORDERS_FULL_ORDER_PAYLOAD_EVENT = 'POS_ORDERS_FULL_ORDER_PAYLOAD_EVENT',
  POS_ORDERS_FULL_ORDER_PAYLOAD_EVENT_CREATION = 'POS_ORDERS_FULL_ORDER_PAYLOAD_EVENT_CREATION',
  POS_ORDERS_FULL_ORDER_PAYLOAD_EVENT_ACCEPTED = 'POS_ORDERS_FULL_ORDER_PAYLOAD_EVENT_ACCEPTED',
  POS_ORDERS_FULL_ORDER_PAYLOAD_EVENT_DISPATCHED = 'POS_ORDERS_FULL_ORDER_PAYLOAD_EVENT_DISPATCHED',
  POS_ORDERS_FULL_ORDER_PAYLOAD_EVENT_COMPLETED = 'POS_ORDERS_FULL_ORDER_PAYLOAD_EVENT_COMPLETED',
  POS_ORDERS_NOTIFICATION_EVENT = 'POS_ORDERS_NOTIFICATION_EVENT',
  POS_ORDERS_NOTIFICATION_EVENT_ACCEPTED = 'POS_ORDERS_NOTIFICATION_EVENT_ACCEPTED',
  POS_ORDERS_NOTIFICATION_EVENT_COMPLETED = 'POS_ORDERS_NOTIFICATION_EVENT_COMPLETED',
  POS_ORDERS_NOTIFICATION_EVENT_STARTED = 'POS_ORDERS_NOTIFICATION_EVENT_STARTED',
  POS_ORDERS_NOTIFICATION_EVENT_DISPATCHED = 'POS_ORDERS_NOTIFICATION_EVENT_DISPATCHED',
  POS_ORDERS_NOTIFICATION_EVENT_CANCELLED = 'POS_ORDERS_NOTIFICATION_EVENT_CANCELLED',
  POS_ORDERS_NOTIFICATION_EVENT_REJECTED = 'POS_ORDERS_NOTIFICATION_EVENT_REJECTED',
  POS_ORDERS_NOTIFICATION_EVENT_PREPARED = 'POS_ORDERS_NOTIFICATION_EVENT_PREPARED',
  POS_ORDERS_DELIVERY_EVENT = 'POS_ORDERS_DELIVERY_EVENT',
  POS_ORDERS_DELIVERY_EVENT_STARTED = 'POS_ORDERS_DELIVERY_EVENT_STARTED',
  POS_ORDERS_DELIVERY_EVENT_ASSIGNED = 'POS_ORDERS_DELIVERY_EVENT_ASSIGNED',
  POS_ORDERS_DELIVERY_EVENT_PICKUP = 'POS_ORDERS_DELIVERY_EVENT_PICKUP',
  POS_ORDERS_DELIVERY_EVENT_COMPLETED = 'POS_ORDERS_DELIVERY_EVENT_COMPLETED',
  POS_ORDERS_DELIVERY_EVENT_CANCELLED = 'POS_ORDERS_DELIVERY_EVENT_CANCELLED',
  POS_ORDER_LIFECYCLE = 'POS_ORDER_LIFECYCLE',
  POS_ORDER_LIFECYCLE_EVENT = 'POS_ORDER_LIFECYCLE_EVENT',
  POS_ORDER_LIFECYCLE_EVENT_ACCEPT = 'POS_ORDER_LIFECYCLE_EVENT_ACCEPT',
  POS_ORDER_LIFECYCLE_EVENT_START = 'POS_ORDER_LIFECYCLE_EVENT_START',
  POS_ORDER_LIFECYCLE_EVENT_PREPARED = 'POS_ORDER_LIFECYCLE_EVENT_PREPARED',
  POS_ORDER_LIFECYCLE_EVENT_CANCELLED = 'POS_ORDER_LIFECYCLE_EVENT_CANCELLED',
  POS_ORDER_LIFECYCLE_EVENT_REJECT = 'POS_ORDER_LIFECYCLE_EVENT_REJECT',
  POS_ORDER_LIFECYCLE_EVENT_DISPATCH = 'POS_ORDER_LIFECYCLE_EVENT_DISPATCH',
  POS_ORDER_LIFECYCLE_EVENT_COMPLETE = 'POS_ORDER_LIFECYCLE_EVENT_COMPLETE',
  POS_MENU_MODIFIER_AVAILABILITY = 'POS_MENU_MODIFIER_AVAILABILITY',
  POS_MENU_AVAILABILITY = 'POS_MENU_AVAILABILITY',
  POS_RECIPE = 'POS_RECIPE',
  POS_RECIPE_MAX_QTY_SUP = 'POS_RECIPE_MAX_QTY_SUP',
  POS_RECIPE_MODIFIERS = 'POS_RECIPE_MODIFIERS',
  POS_RECIPE_MODIFIER_GROUP_MAX_MIN_QTY_SUP = 'POS_RECIPE_MODIFIER_GROUP_MAX_MIN_QTY_SUP',
  POS_RECIPE_MODIFIER_MAX_QTY_SUP = 'POS_RECIPE_MODIFIER_MAX_QTY_SUP',
  POS_RECIPE_IMAGE_SUP = 'POS_RECIPE_IMAGE_SUP',
  POS_RECIPE_IMAGE_SUP_MODIFIERS = 'POS_RECIPE_IMAGE_SUP_MODIFIERS',
  POS_RECIPE_PRICE_UPDATES = 'POS_RECIPE_PRICE_UPDATES',
  POS_RECIPE_PRICE_UPDATES_MODIFIERS = 'POS_RECIPE_PRICE_UPDATES_MODIFIERS',
  POS_RECIPE_AVAILABILITY = 'POS_RECIPE_AVAILABILITY',
  POS_RECIPE_AVAILABILITY_MODIFIER = 'POS_RECIPE_AVAILABILITY_MODIFIER',
  POS_RECIPES = 'POS_RECIPES',
  POS_RECIPE_SYNC_STATUS = 'POS_RECIPE_SYNC_STATUS',
  POS_RECIPE_SYNC_STATUS_RECEIVE = 'POS_RECIPE_SYNC_STATUS_RECEIVE',
}

export enum OrderCapabilityValidationTests {
  FORM_TEST_ORDER_TYPES = 'Check if at least one order type selected',
  FORM_TEST_ORDER_PICKUP_TIME = 'Check if order pick up time selected with food aggregator type',
  FORM_TEST_PAYMENT_METHOD = 'Check if at least one payment method selected',
}
export enum OrderCapabilityValidationError {
  ERROR_TEST_ORDER_TYPES = 'At least one order type must be selected.',
  ERROR_TEST_ORDER_PICKUP_TIME = 'Pick up time must be selected with food aggregator order type.',
  ERROR_TEST_PAYMENT_METHOD = 'At least one payment method must be selected.',
}

export enum DeliveryCapabilityStatus {
  DRIVER_ASSIGNED = 'DRIVER_ASSIGNED',
  DRIVER_ARRIVED = 'DRIVER_ARRIVED',
  DELIVERY_JOB_STARTED = 'DELIVERY_JOB_STARTED',
  DELIVERY_JOB_COMPLETED = 'DELIVERY_JOB_COMPLETED',
}

export enum PaymentMethods {
  PAID = 'Paid',
  UNPAID = 'Unpaid',
}

export enum CapabilitySelectionType {
  CHECKBOX = 'CHECKBOX',
  RADIO_BTN = 'RADIO_BTN',
}

export enum ChoiceSelectionType {
  MULTIPLE_SELECT = 'MULTIPLE_SELECT',
}

export type IntegrationCapabilityDefinition = {
  id: DeliveryCapabilityIdType | OrderCapabilityIdType | PosCapabilityIdType;
  capability: string;
  capabilitySelectionType: CapabilitySelectionType;
  choiceSelectionType: ChoiceSelectionType;
  choices: DeliveryCapabilityStatus[] | PaymentMethods[];
  relatedCapabilities: IntegrationCapabilityDefinition[];
  childCapabilities: IntegrationCapabilityDefinition[];
  description: string;
  mandatory: boolean;
  disabled: boolean;
  idsOfTheCapabilitiesShouldBeOmitted: Array<string>;
};

export type IntegrationDefinition = {
  id: IntegrationId;
  name: string;
  description: string;
  linkToContract: string;
  apiList: IntegrationApiSummary[];
  capabilities: IntegrationCapabilityDefinition[];
};

export type IntegrationId = {
  id: string;
};

export type IntegrationApiSummary = {
  name: string;
  url: string;
};

export type IntegrationActivationStatusWithTime = {
  time: string;
  status: IntegrationActivationStatus;
};

export type PartnerIntegrationStatus = {
  partnerId: string;
  integration: IntegrationId;
  status: IntegrationActivationStatus[];
  statusUpdates: IntegrationActivationStatusWithTime[];
};

export enum ParameterType {
  BOOLEAN = 'BOOLEAN',
  STRING = 'STRING',
  NUMBER = 'NUMBER',
}

export enum ParameterTypeTexts {
  BOOLEAN = 'Boolean',
  STRING = 'Text',
  NUMBER = ' Number',
}

export type ParameterInfo = {
  value: string;
  type: ParameterType;
};

export type OrganizationIntegrationParameters = {
  integration: IntegrationId;
  organizationId: string;
  name: string;
  description: string;
  isDisabled: boolean;
  parameterMap: { [key: string]: ParameterInfo };
  customApplicationUrl: CustomApplicationUrl;
};

export type CustomApplicationUrl = {
  partnerApplicationUrl: string;
  brandApplicationUrl: string;
  locationApplicationUrl: string;
  channelApplicationUrl: string;
};

export type CustomPosIntegrationConfiguration = {
  autoConfigureNewLocations: boolean;
  menuCreationViaIntegration: boolean;
  perBrandPerLocationConfig: boolean;
  updateOrderStatusHistory: boolean;
  sendOrderFailureNotification: boolean;
  sendInvoiceNumber: boolean;
  orderAutoAcceptedAfterSeconds: number;
  handlingOrderLifeCycle: boolean;
  defaultWebhookUrl: string;
};

export type Parameter = {
  integration: IntegrationId;
  organizationId: string;
  globalSettings: { [key: string]: ParameterInfo };
  customApplicationUrl: CustomApplicationUrl;
  customPosIntegrationConfiguration: CustomPosIntegrationConfiguration;
};

export interface SettingsItem {
  key: string;
  value: string;
  type: ParameterType;
}

export enum IntegrationActivationStatus {
  CONTRACT_SUBMITTED = 'CONTRACT_SUBMITTED',
  CONTRACT_REJECTED = 'CONTRACT_REJECTED',
  CAPABILITIES_SUBMITTED = 'CAPABILITIES_SUBMITTED',
  API_KEYS_CREATED = 'API_KEYS_CREATED',
  WEB_HOOKS_REGISTERED = 'WEB_HOOKS_REGISTERED',
  CUSTOMIZED_PARAMETERS_CREATED = 'CUSTOMIZED_PARAMETERS_CREATED',
  SANDBOX_ACTIVATED = 'SANDBOX_ACTIVATED',
  CONTRACT_APPROVED = 'CONTRACT_APPROVED',
  TEST_CASES_SUBMITTED_FOR_APPROVAL = 'TEST_CASES_SUBMITTED_FOR_APPROVAL',
  TEST_CASES_APPROVED_BY_ADMIN = 'TEST_CASES_APPROVED_BY_ADMIN',
  TEST_CASES_REJECTED_BY_ADMIN = 'TEST_CASES_REJECTED_BY_ADMIN',
  GO_LIVE_REQUEST_SUBMITTED = 'GO_LIVE_REQUEST_SUBMITTED',
  GO_LIVE_ON_HOLD = 'GO_LIVE_ON_HOLD',
  GO_LIVE_APPROVED = 'GO_LIVE_APPROVED',
  LIVE = 'LIVE',
}

export type ActivateIntegrationPayload = { organizationId: string; integration: IntegrationId };

export type IntegrationCapabilityRow = {
  capabilityId: DeliveryCapabilityIdType | OrderCapabilityIdType | PosCapabilityIdType;
  value: string;
};

export type IntegrationCapability = {
  integrationId: IntegrationId;
  integrationCapabilityRows: IntegrationCapabilityRow[];
};

export type Capability = {
  organizationId: string;
  integrationCapabilities: IntegrationCapability[];
};

export type CapabilityFormikValue = {
  [key: string]: IntegrationCapabilityDefinition & {
    value: string | boolean;
  };
};

export type IntegrationID = 'order' | 'delivery' | 'pos';

export enum IntegrationType {
  'order' = 'ORDER',
  'delivery' = 'DELIVERY',
  'pos' = 'POS',
}

export const capabilitiesDisabledStatuses: IntegrationActivationStatus[] = [
  IntegrationActivationStatus.TEST_CASES_SUBMITTED_FOR_APPROVAL,
  IntegrationActivationStatus.TEST_CASES_APPROVED_BY_ADMIN,
  IntegrationActivationStatus.GO_LIVE_REQUEST_SUBMITTED,
  IntegrationActivationStatus.GO_LIVE_ON_HOLD,
  IntegrationActivationStatus.GO_LIVE_APPROVED,
  IntegrationActivationStatus.LIVE,
];
