import {
  Capability,
  IntegrationDefinition,
  IntegrationId,
  PartnerIntegrationStatus,
} from '../../models/integration';
import { actionCreator } from '../common';
import { IntegrationActionTypes } from '../types';

export const integrationActionCreator = {
  changeIntegrationDefinition: actionCreator<IntegrationDefinition[]>(
    IntegrationActionTypes.CHANGE_INTEGRATION_DEFINITIONS,
  ),
  changeActivatedIntegration: actionCreator<PartnerIntegrationStatus[]>(
    IntegrationActionTypes.CHANGE_ACTIVATED_INTEGRATIONS,
  ),
  changeUpdateActivatedIntegration: actionCreator<PartnerIntegrationStatus>(
    IntegrationActionTypes.CHANGE_UPDATE_ACTIVATED_INTEGRATIONS,
  ),
  changeIsAgreeToCoditions: actionCreator<boolean>(
    IntegrationActionTypes.CHANGE_IS_AGREE_TO_CONDITIONS,
  ),
  changePdfCount: actionCreator<number>(IntegrationActionTypes.CHNAGE_INTEGRATION_PDF_PAGES_COUNT),
  changeCapability: actionCreator<Capability | null>(IntegrationActionTypes.CHANGE_CAPABILITY),
  changeActivatingFlow: actionCreator<IntegrationId | null>(
    IntegrationActionTypes.CHANGE_ACTIVATING_FLOW,
  ),
};
