import {
  Card,
  Title,
  useMantineTheme,
  List,
  ThemeIcon,
  Loader,
  Button,
  Text,
  UnstyledButton,
  LoadingOverlay,
  Badge,
  Group,
  Popover,
  CopyButton,
  ActionIcon,
  Tooltip,
  Paper,
} from '@mantine/core';
import { sandBoxSitesCardStyles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/common';
import { AuthenticationState, LoaderState, SandboxState } from '../../../redux/reducers';
import { useCallback } from 'react';
import { convertEnumToString } from '../../../utils/string';
import {
  IconCheck,
  IconCircleDashed,
  IconCopy,
  IconPencil,
  IconRefreshAlert,
  IconTrash,
} from '@tabler/icons-react';
import { SandboxStatus } from '../../../models';
import { useNavigate } from 'react-router-dom';
import { loaderActionCreator, sandboxActionCreator } from '../../../redux/actions';
import { notifications } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';
import { sandboxService } from '../../../service';
import { useDisclosure } from '@mantine/hooks';

interface SandBoxSitesCardProps {
  sandboxId: string;
  refetchSummaryData: () => void;
}

const SandBoxSitesCard: React.FC<SandBoxSitesCardProps> = ({ sandboxId, refetchSummaryData }) => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [opened, { close, open }] = useDisclosure(false);

  const { sandboxSummaryData } = useSelector<RootState, SandboxState>((state: RootState) => {
    return state.sandbox;
  });

  const { userData } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });

  const { isLoading } = useSelector<RootState, LoaderState>((state: RootState) => {
    return state.loader;
  });

  const deleteSandbox = useMutation({
    mutationFn: (payload: { organizationId: string; sandBoxId: string }) => {
      return sandboxService.deleteSandbox(payload);
    },
    onSuccess: () => {
      dispatch(loaderActionCreator.changeLoaderStatus(true));
      setTimeout(() => {
        refetchSummaryData();
        dispatch(loaderActionCreator.changeLoaderStatus(false));
      }, 30000);
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const getSandboxData = useCallback(
    () => sandboxSummaryData.find(({ id }) => id === sandboxId),
    [sandboxSummaryData, sandboxId],
  );

  const getStatusList = useCallback(() => {
    return (
      <List spacing="xs" size="sm" center>
        {getSandboxData()?.commonSandboxStepsAndStatus?.stepsWithStatus?.map((stepStatus) => {
          return (
            <List.Item
              icon={
                <ThemeIcon color="blue" size={24} radius="xl">
                  <IconCircleDashed size="1rem" />
                </ThemeIcon>
              }
              key={stepStatus.step.id}
            >{`${stepStatus.step.name} - ${convertEnumToString(stepStatus.status)}`}</List.Item>
          );
        })}
      </List>
    );
  }, [getSandboxData]);

  const getErrorStep = useCallback(() => {
    return getSandboxData()?.commonSandboxStepsAndStatus?.stepsWithStatus?.find(
      (stepStatus) => stepStatus.status !== SandboxStatus.CREATED,
    );
  }, [getSandboxData]);

  const getDescriptionValues = useCallback(() => {
    if (!getSandboxData()?.sandboxDetails || getSandboxData()?.sandboxDetails?.length === 0) {
      return null;
    }
    return getSandboxData()
      ?.sandboxDetails?.filter((sandboxData) => sandboxData.value.startsWith('http'))
      ?.map(({ name, value, description }) => {
        if (value.length <= 0) return null;
        return (
          <Card shadow="sm" padding="lg" radius="md" withBorder mb="12px">
            <Group position="apart" mt="md" mb="xs">
              <Text weight={500} data-cy={`sandbox-sites-${name}`}>
                {name}
              </Text>
            </Group>

            <Text size="sm" color="dimmed" data-cy={`sandbox-sites-${name}-description`}>
              {description}
            </Text>

            <Button
              variant="light"
              color="blue"
              fullWidth
              mt="md"
              radius="md"
              onClick={() => {
                value.startsWith('http') && window.open(value, '_blank');
              }}
              data-cy={`sandbox-sites-${name}-button`}
            >
              {'Try it Yourself >>'}
            </Button>
          </Card>
        );
      });
  }, [getSandboxData]);

  const getLoginDetails = useCallback(() => {
    if (!getSandboxData()?.sandboxDetails || getSandboxData()?.sandboxDetails?.length === 0) {
      return null;
    }
    const userNameData = getSandboxData()?.sandboxDetails.find(
      (sandboxDetail) => sandboxDetail.name === 'Login Username',
    );
    const passwordData = getSandboxData()?.sandboxDetails.find(
      (sandboxDetail) => sandboxDetail.name === 'Login Password',
    );

    return (
      <Card shadow="sm" padding="lg" radius="md" withBorder mb="12px">
        <Group position="apart" mt="md" mb="xs">
          <Text weight={500} data-cy="sandbox-sites-user-credentials-title">
            User Credentials
          </Text>
        </Group>

        <Card.Section ml="0rem" mr="0.5rem">
          <Group position="left" mt="md" mb="xs">
            <Text weight={400}>Username : </Text>
            <Badge color="brown" variant="light">
              <Text weight={500} data-cy="sandbox-sites-user-credentials-username">
                {userNameData?.value}
              </Text>
            </Badge>
            <CopyButton value={userNameData?.value ?? ''} timeout={2000}>
              {({ copied, copy }) => (
                <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                  <ActionIcon color={copied ? 'teal' : 'gray'} onClick={copy}>
                    {copied ? <IconCheck size="1rem" /> : <IconCopy size="1rem" />}
                  </ActionIcon>
                </Tooltip>
              )}
            </CopyButton>
          </Group>
        </Card.Section>

        <Text size="sm" color="dimmed" data-cy="sandbox-sites-user-credentials-access">
          {passwordData?.value}
        </Text>
      </Card>
    );
  }, [getSandboxData]);

  return (
    <Paper sx={sandBoxSitesCardStyles(theme)} id={sandboxId} shadow="sm">
      <LoadingOverlay visible={deleteSandbox.isLoading || isLoading} />
      <Card className="sandbox-sites-card">
        <Group position="apart" mt="md" mb="xs" className="header-section">
          <Group>
            <Title order={5} data-cy={`sandbox-sites-title`}>
              {getSandboxData()?.name}
            </Title>

            <Popover width={150} position="right" withArrow shadow="md" opened={opened}>
              <Popover.Target>
                <Badge
                  color={
                    getSandboxData()?.overallStatus === SandboxStatus.CREATED ||
                    getSandboxData()?.overallStatus === SandboxStatus.CREATING
                      ? 'green'
                      : 'red'
                  }
                  variant="light"
                  onMouseEnter={open}
                  onMouseLeave={close}
                  data-cy={`sandbox-sites-status`}
                >
                  {convertEnumToString(getSandboxData()?.overallStatus ?? '')}
                </Badge>
              </Popover.Target>
              {getSandboxData()?.overallStatus === SandboxStatus.FAILED && (
                <Popover.Dropdown sx={{ pointerEvents: 'none' }}>
                  <Text size="sm" data-cy={`sandbox-sites-error-message`}>
                    {getErrorStep()?.step.name
                      ? `${getErrorStep()?.step.name} Failed`
                      : 'Unknown Error Occured'}
                  </Text>
                </Popover.Dropdown>
              )}
            </Popover>
          </Group>

          {getSandboxData()?.overallStatus !== SandboxStatus.CREATING && (
            <Group className="button-group">
              {getSandboxData()?.overallStatus === SandboxStatus.FAILED && (
                <UnstyledButton
                  className="retry-button"
                  onClick={() => {
                    dispatch(sandboxActionCreator.retrySandboxCreation(true));
                    navigate('/sandbox-sites/new');
                  }}
                  data-cy={`sandbox-sites-retry-button`}
                >
                  <IconRefreshAlert strokeWidth={2} color="orange" />
                </UnstyledButton>
              )}
              {getSandboxData()?.overallStatus === SandboxStatus.CREATED && (
                <UnstyledButton
                  className="edit-button"
                  onClick={() => {
                    dispatch(sandboxActionCreator.toggleIsEditingSandbox(sandboxId));
                    navigate(`/sandbox-sites/edit/${sandboxId}`);
                  }}
                  data-cy={`sandbox-sites-edit-button`}
                >
                  <IconPencil strokeWidth={2} color="orange" />
                </UnstyledButton>
              )}
              <UnstyledButton
                className="delete-button"
                onClick={() =>
                  deleteSandbox.mutate({
                    organizationId: userData.organizationId,
                    sandBoxId: sandboxId,
                  })
                }
                data-cy={`sandbox-sites-delete-button`}
              >
                <IconTrash color="#fa5252" />
              </UnstyledButton>
            </Group>
          )}
          {getSandboxData()?.overallStatus === SandboxStatus.CREATING ? (
            <Loader variant="oval" />
          ) : null}
        </Group>

        {getSandboxData()?.overallStatus === SandboxStatus.CREATING && (
          <div className="sandbox-sites-card-section">{getStatusList()}</div>
        )}
        <div className="description-values">{getLoginDetails()}</div>
        <div className="description-values">{getDescriptionValues()}</div>
      </Card>
    </Paper>
  );
};

export default SandBoxSitesCard;
