import { MantineTheme, Sx } from '@mantine/styles';

export const deleteDialogStyles = (theme: MantineTheme): Sx => {
  return {
    '.mantine-Modal-content': {
      borderRadius: '12px',
    },
    '.confirmationDialog__container': {
      padding: '8px 24px',
    },
    '.confirmationDialog__iconBox': {
      width: '64px',
      height: '64px',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&--primary': {
        backgroundColor: theme.colors.grubtech[0],
      },
      '&--danger': {
        backgroundColor: "#B61D4C",
      },
      '&--warning': {
        backgroundColor: theme.colors.yellow[5],
      },
    },
    '.confirmationDialog__divider': {
      marginTop: 'auto',
      width: '100%',
    },
    '.confirmationDialog__buttons': {
      width: '100%',
      button: {
        width: '50%',
        '&:first-of-type': {
          borderColor: theme.colors.gray[5],
          color: theme.colors.gray[9],
        },
      },
      '&--primary button': {
        '&:last-of-type': {
          backgroundColor: theme.colors.grubtech[0],
        },
      },
      '&--danger button': {
        '&:last-of-type': {
          backgroundColor: "#B61D4C",
        },
      },
      '&--warning button': {
        '&:last-of-type': {
          backgroundColor: theme.colors.yellow[6],
        },
      },
    },
    '.confirmationDialog__textContainer': {
      margin: '24 0 20 0 !important',
      fontSize: '20px !important',
      fontWeight: 500,
    },
    '.confirmationDialog__checkBox': {
      marginRight: 'auto',
    },
  };
};
