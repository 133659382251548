import { integrationAxios } from '../config/axios.config';
import {
  ActivateIntegrationPayload,
  Capability,
  IntegrationDefinition,
  OrganizationIntegrationParameters,
  Parameter,
  PartnerIntegrationStatus,
} from '../models/integration';

const integratioService = {
  getIntegrations: async (organizationId: string): Promise<IntegrationDefinition[]> => {
    const { data } = await integrationAxios.get(`/${organizationId}`);
    return data;
  },
  getActivatedIntegrations: async (partnerId: string): Promise<PartnerIntegrationStatus[]> => {
    const { data } = await integrationAxios.get(`/activated/${partnerId}`);
    return data;
  },
  activateIntegration: async (
    payload: ActivateIntegrationPayload,
  ): Promise<PartnerIntegrationStatus> => {
    const { data } = await integrationAxios.put(
      `/activate/${payload.organizationId}/${payload.integration.id}`,
      payload,
    );
    return data;
  },
  getIntegrationCapability: async (organizationId: string): Promise<Capability | string> => {
    const { data } = await integrationAxios.get(`/capability/${organizationId}`);
    return data;
  },
  createIntegrationCapability: async (
    organizationId: string,
    integrationId: string,
    capability: Capability,
  ): Promise<any> => {
    const { data } = await integrationAxios.post(
      `/capability/${organizationId}/byIntegration/${integrationId}`,
      capability,
    );
    return data;
  },

  getIntegrationParameterSummary: async (
    organizationId: string,
  ): Promise<OrganizationIntegrationParameters[]> => {
    const { data } = await integrationAxios.get(`/params/integration-summary/${organizationId}`);
    return data;
  },

  getIntegrationParameters: async (
    organizationId: string,
    integrationId: string | undefined,
  ): Promise<Parameter> => {
    const { data } = await integrationAxios.get(`/params/${organizationId}/${integrationId}`);
    return data;
  },

  createUpdateIntegrationParameters: async (
    organizationId: string,
    parameters: Parameter,
  ): Promise<Parameter> => {
    const { data } = await integrationAxios.put(
      `/params/${organizationId}`,
      parameters,
    );
    return data;
  },
};

export default integratioService;
