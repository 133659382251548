import PageLayout from '../../components/PageLayout';
import PageHeader from '../../components/PageHeader';
import { sandboxSitesStyles } from './styles';
import {
  Button,
  Flex,
  Paper,
  Text,
  Alert,
  SimpleGrid,
  useMantineTheme,
  LoadingOverlay,
} from '@mantine/core';
import { IconAlertTriangle, IconBuildingStore, IconPlus } from '@tabler/icons-react';
import { Link, useNavigate } from 'react-router-dom';
import SandBoxSitesCard from './SandBoxSitesCard';
import { sandboxService } from '../../service';
import { notifications } from '@mantine/notifications';
import { useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/common';
import { AuthenticationState, SandboxState } from '../../redux/reducers';
import { SandboxStatus } from '../../models';
import { sandboxActionCreator } from '../../redux/actions';
import { useEffect } from 'react';
import organizationService from '../../service/organizations.service';
import { organizationActionCreator } from '../../redux/actions/organization';

//Temporary data - replace with redux logic
const webhooksAdded = true;

const SandboxSites: React.FC = () => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userData } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });

  const { sandboxSummaryData, newSandboxCreated } = useSelector<RootState, SandboxState>(
    (state: RootState) => {
      return state.sandbox;
    },
  );

  useEffect(() => {
    const timer = setInterval(() => {
      if (
        sandboxSummaryData.some(
          (sandboxSummary) => sandboxSummary.overallStatus === SandboxStatus.CREATING,
        )
      ) {
        refetch();
      }
    }, 3000);

    return () => clearInterval(timer);
    // eslint-disable-next-line
  }, [sandboxSummaryData]);

  useEffect(() => {
    if (newSandboxCreated) {
      refetch();
      dispatch(sandboxActionCreator.newSandboxCreated(false));
    }
    // eslint-disable-next-line
  }, [newSandboxCreated]);

  useEffect(() => {
    dispatch(sandboxActionCreator.changeSandboxSummaryData([]));
    // eslint-disable-next-line
  }, []);

  const { isLoading: isLoadingOrganization } = useQuery({
    queryKey: ['get-organization'],
    queryFn: () => organizationService.getOrganization(userData.organizationId || ''),
    onSuccess: (data) => {
      dispatch(organizationActionCreator.changeOrganization(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const { isLoading: isLoadingSanboxStatus, refetch } = useQuery({
    queryKey: ['sandbox-summary-data', userData.organizationId],
    queryFn: () => sandboxService.getSandboxStatus(userData.organizationId),
    onSuccess: (data) => {
      dispatch(sandboxActionCreator.changeSandboxSummaryData(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const onCreateNewSiteClick = () => {
    navigate('/sandbox-sites/new');
  };

  const getFilteredSandboxSummaryData = () => {
    return (
      sandboxSummaryData?.filter(
        (sandboxData) => sandboxData.overallStatus !== SandboxStatus.DELETED,
      ) ?? []
    );
  };

  const isCreateDisabled = () => {
    if (!getFilteredSandboxSummaryData() || getFilteredSandboxSummaryData()?.length === 0)
      return false;
    if (getFilteredSandboxSummaryData().length > 0) return true;
    return false;
  };

  return (
    <PageLayout
      id={'sandbox-sites-page'}
      sx={sandboxSitesStyles(theme)}
      headerSection={
        <PageHeader
          id={'sandbox-sites-page'}
          title={'Sandbox'}
          description={'Create and manage sandbox and associated webhooks here'}
        />
      }
    >
      <LoadingOverlay visible={isLoadingSanboxStatus || isLoadingOrganization} />

      {!webhooksAdded && (
        <div className="sandbox-sites-alert">
          <Alert icon={<IconAlertTriangle size={18} />} color="red" variant="filled">
            <Text>
              Before creating a sandbox site, please input webhook URLs for available products
              within the Suites you've activated.{' '}
              <Link
                to={'/webhooks'}
                style={{
                  color: '#fff',
                  textDecoration: 'underline',
                }}
              >
                Click here to set your webhooks
              </Link>
            </Text>
          </Alert>
        </div>
      )}
      <Paper shadow="xs" className="sandbox-sites-paper">
        {getFilteredSandboxSummaryData()?.length === 0 ? (
          <Flex
            className="sandbox-sites-empty-state"
            direction="column"
            align="center"
            justify="center"
            gap={20}
          >
            <IconBuildingStore color={'gray'} size={36} />
            <Text weight={'bold'} data-cy="no-sandbox-text">
              You have no sandbox yet
            </Text>
            <Button
              leftIcon={<IconPlus size={16} />}
              onClick={onCreateNewSiteClick}
              data-cy="create-new-site-button-empty-state"
              disabled={isCreateDisabled()}
            >
              Create a new site
            </Button>
          </Flex>
        ) : (
          <div>
            <Flex align={'center'} justify={'space-between'}>
              <Button
                onClick={onCreateNewSiteClick}
                data-cy="create-new-site-button"
                disabled={isCreateDisabled()}
              >
                Create new sandbox site
              </Button>
              {/* <Flex gap={16} align={'center'}>
                <Select
                  data={[
                    { value: 'newest', label: 'Newest first' },
                    { value: 'oldest', label: 'Oldest first' },
                  ]}
                  defaultValue="newest"
                  data-cy="sandbox-sites-sort-select"
                />
                <TextInput
                  placeholder="Search by any field"
                  icon={<IconSearch size={14} stroke={1.5} />}
                  className="sandbox-sites-search-input"
                  data-cy="sandbox-sites-search-input"
                />
              </Flex> */}
            </Flex>
            <div className="sandbox-sites-container">
              <SimpleGrid cols={2} spacing={'md'}>
                {getFilteredSandboxSummaryData()?.map(({ id }) => (
                  <SandBoxSitesCard sandboxId={id} key={id} refetchSummaryData={refetch} />
                ))}
              </SimpleGrid>
            </div>
          </div>
        )}
      </Paper>
    </PageLayout>
  );
};

export default SandboxSites;
