/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState, useRef, ChangeEvent } from 'react';
import {
  Box,
  Flex,
  Text,
  Button,
  Loader,
  Paper,
  LoadingOverlay,
  useMantineTheme,
} from '@mantine/core';
import { ndaDocumentPageStyles } from './style';
import PageLayout from '../../../components/PageLayout/PageLayout';
import PageHeader from '../../../components/PageHeader/PageHeader';
import { useNavigate } from 'react-router-dom';
import { Document, Page } from 'react-pdf';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/common';
import { AuthenticationState, NdaState } from '../../../redux/reducers';
import { ndaActionCreator } from '../../../redux/actions/nda';
import { useMutation, useQuery } from '@tanstack/react-query';
import ndaService from '../../../service/nda.service';
import { notifications } from '@mantine/notifications';
import { NdaStatus } from '../../../models/nda';

const NDADocumentPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useMantineTheme();

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [submitDate, setSubmitDate] = useState<string>('');
  const [pdf, setPdf] = useState<File | null>(null);
  const [isFileLoadedSuccessfully, setIsFileLoadedSuccessfully] = useState<boolean>(false);

  const { userData } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });

  const { ndaPdfPageCount } = useSelector<RootState, NdaState>((state: RootState) => {
    return state.nda;
  });

  const onLoadSuccess = ({ numPages }: { numPages: number }) => {
    setIsFileLoadedSuccessfully(true);
    dispatch(ndaActionCreator.changeNdaPdfCount(numPages));
  };

  const uploadFile = useMutation({
    mutationFn: (payload: { organizationId: string; file: File }) => {
      return ndaService.uploadFile(payload.organizationId, payload.file);
    },
    onSuccess: (data) => {
      notifications.show({
        title: 'Successfully Updated',
        message: 'File is uploaded successfully',
        color: 'green',
      });
      refetchNdaStatus();
      navigate('/manage-api-access');
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const downloadSignedPdf = useMutation({
    mutationFn: (payload: { organizationId: string }) => {
      return ndaService.downloadSignedPdf(payload.organizationId);
    },
    onSuccess: ({ data, fileName }) => {
      const blob = new Blob([data], { type: 'application/pdf' });
      const file = new File([blob], 'signedNdaPdf.pdf', { type: 'application/pdf' });
      setPdf(file);

      const regex = /\d{2}\/\d{2}\/\d{4}/; // Regular expression to match a date in MM/DD/YYYY format
      const match = fileName.match(regex);
      if (match) {
        setSubmitDate(match[0]);
      }
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const downloadInitialPdf = useMutation({
    mutationFn: () => {
      return ndaService.downloadInitialPdf();
    },
    onSuccess: (data) => {
      dispatch(ndaActionCreator.changeInitialPdf(data));
      const url = URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'GT_NDA.pdf');
      document.body.appendChild(link);
      link.click();
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const {
    data: ndaStatus,
    isLoading: isLoadingNdaStatus,
    refetch: refetchNdaStatus,
  } = useQuery({
    queryKey: ['get-nda-status', userData.organizationId],
    queryFn: () => ndaService.getNdaStatus(userData.organizationId),
    onSuccess: (data) => {
      dispatch(ndaActionCreator.changeNdaStatus(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  useEffect(() => {
    if (ndaStatus === NdaStatus.SUBMITTED || ndaStatus === NdaStatus.APPROVED) {
      downloadSignedPdf.mutate({ organizationId: userData.organizationId });
    }
  }, [ndaStatus]);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setPdf(file);
    }
  };

  const openFilePicker = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <PageLayout
        id={'your-nda-page'}
        sx={ndaDocumentPageStyles(theme)}
        headerSection={
          <PageHeader
            id={'your-nda'}
            title={'Your NDA'}
            backText={'Back'}
            onClickBack={() => navigate('/manage-api-access')}
          />
        }
      >
        <LoadingOverlay visible={isLoadingNdaStatus} />

        <Box>
          <Flex className="nda-page-container">
            <Flex className="nda-page-content" direction={'column'}>
              <Text className="main-title">Non-disclosure agreement </Text>
              <Text className="regular-text">
                This non-disclosure agreement between your company and Grubtech is to safeguard both
                parties from data related risks. You will be required to submit a signed copy of
                this document in order to proceed further.
              </Text>
              <Text className="sub-title">Download your NDA</Text>
              <Text className="regular-text">
                You will be able to download the standard NDA in PDF format.
              </Text>
              <Button
                className="nda-button"
                onClick={() => {
                  downloadInitialPdf.mutate();
                }}
              >
                Download
              </Button>
              <Text className="sub-title">Submit your NDA for approval</Text>
              <Text className="regular-text">
                Please share the signed NDA with us which will be reviewed by our administrator. You
                will get an email notification once approved.
              </Text>
              <div>
                <input
                  type="file"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                  ref={fileInputRef}
                />
                <Button
                  className="nda-button"
                  onClick={openFilePicker}
                  disabled={ndaStatus === NdaStatus.SUBMITTED || ndaStatus === NdaStatus.APPROVED}
                >
                  Upload
                </Button>
              </div>
              {pdf != null && (
                <>
                  <Document
                    file={pdf}
                    onLoadError={() => setIsFileLoadedSuccessfully(false)}
                    onLoadSuccess={onLoadSuccess}
                    className={'nda-pdf-document'}
                    loading={<Loader />}
                    error={
                      <Text color="red">
                        You have uploaded an invalid file type or a corrupted file. Please upload
                        your NDA in PDF format.
                      </Text>
                    }
                  >
                    {Array.from(new Array(ndaPdfPageCount), (el, index) => (
                      <Paper key={`page_${index + 1}`} shadow="xs" withBorder>
                        <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                      </Paper>
                    ))}
                    {submitDate && (
                      <Paper shadow="md" className="footer">
                        <Text>NDA submitted on {submitDate}</Text>
                      </Paper>
                    )}
                  </Document>
                </>
              )}
            </Flex>
            <Flex className="nda-button-content" direction={'column'}>
              <Button
                className="nda-submit-button"
                disabled={
                  ndaStatus === NdaStatus.SUBMITTED ||
                  ndaStatus === NdaStatus.APPROVED ||
                  pdf === null ||
                  !isFileLoadedSuccessfully
                }
                onClick={() => {
                  if (pdf != null) {
                    uploadFile.mutate({ organizationId: userData.organizationId, file: pdf });
                  }
                }}
              >
                Submit
              </Button>
            </Flex>
          </Flex>
        </Box>
      </PageLayout>
    </>
  );
};

export default NDADocumentPage;
