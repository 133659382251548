import Organisation from '../modules/Organisation';
import Login from '../modules/authentication/Login';
import Webhooks from '../modules/Webhooks';
import OTP from '../modules/authentication/OTP';
import Signup from '../modules/authentication/Signup';
import SignupDetails from '../modules/authentication/SignupDetails';
import ApiCredentials from '../modules/ApiCredentials/ApiCredentials';
import ManageApiAccess from '../modules/ManageApiAccess/ManageApiAccess';
import { SandboxSites, NewSandboxSite, EditSandboxSite } from '../modules/SandBoxSites';
import Dashboard from '../modules/Dashboard';
import Onboarding from '../modules/Onboarding/Onboarding';
import SetupOrganization from '../modules/Onboarding/SetupOrganization/SetupOrganization';
import FBDataDeletionInstructions from '../modules/authentication/FBDataDeletionInstructions';
import Capabilities from '../modules/ManageApiAccess/Capabilities';
import NDADocumentPage from '../modules/ManageApiAccess/NDADocumentPage';
import AppStoreDetails from '../modules/AppStoreDetails/AppStoreDetails';
import ForgotPassword from '../modules/authentication/ForgotPassword';
import ForgotPasswordSent from '../modules/authentication/ForgotPassword/ForgotPasswordSent';
import CreateNewPassword from '../modules/authentication/ForgotPassword/CreateNewPassword';
import ResetPassword from '../modules/authentication/ResetPassword';
import DocumentationLogin from '../modules/authentication/DocumentationLogin';
import DefinitionSettings from '../modules/DefinitionSettings/DefinitionSettings';
import IntegrationParameters from '../modules/DefinitionSettings/IntegrationParameters/IntegrationParameters';

const publicRoutes = [
  { key: 'login', path: '/login', element: <Login /> },
  { key: 'login-documentation', path: '/documentation-login', element: <DocumentationLogin /> },
  { key: 'forgot-password', path: '/forgot-password', element: <ForgotPassword /> },
  { key: 'reset-password', path: '/reset-password', element: <ResetPassword /> },
  { key: 'forgot-password-sent', path: '/forgot-password-sent', element: <ForgotPasswordSent /> },
  { key: 'create-password', path: '/forgot-password', element: <CreateNewPassword /> },
  { key: 'signup', path: '/signup', element: <Signup /> },
  { key: 'signup-details', path: '/signup-details', element: <SignupDetails /> },
  { key: 'otp-verification', path: '/otp-verification', element: <OTP /> },
  {
    key: 'facebook-data-deletion-instructions',
    path: '/facebook-data-deletion-instructions',
    element: <FBDataDeletionInstructions />,
  },
];

const privateRoutes = [
  { key: 'dashboard', path: '/', element: <Dashboard /> },
  { key: 'dashboard', path: '/dashboard', element: <Dashboard /> },
  { key: 'dashboard/:tabValue', path: '/dashboard/:tabValue', element: <Dashboard /> },
  { key: 'organisation', path: '/organisation', element: <Organisation /> },
  { key: 'organisation/:tabValue', path: '/organisation/:tabValue', element: <Organisation /> },
  { key: 'webhooks', path: '/webhooks', element: <Webhooks /> },
  { key: 'webhooks/:tabValue', path: '/webhooks/:tabValue', element: <Webhooks /> },
  { key: 'manage-api-access', path: '/manage-api-access', element: <ManageApiAccess /> },
  {
    key: '/manage-api-access/capabilities/:integrationId',
    path: '/manage-api-access/capabilities/:integrationId',
    element: <Capabilities />,
  },
  { key: 'sandbox-sites', path: '/sandbox-sites', element: <SandboxSites /> },
  { key: 'sandbox-sites-new', path: '/sandbox-sites/new', element: <NewSandboxSite /> },
  { key: 'sandbox-sites-edit', path: '/sandbox-sites/edit/:id', element: <EditSandboxSite /> },
  { key: 'credentials', path: '/credentials', element: <ApiCredentials /> },
  { key: 'get-started', path: '/get-started', element: <Onboarding /> },
  { key: 'setup-organization', path: '/setup-organization', element: <SetupOrganization /> },
  { key: 'nda', path: '/nda', element: <NDADocumentPage /> },
  { key: 'app-store-details', path: '/app-store-details', element: <AppStoreDetails /> },
  { key: 'definition-settings', path: '/definition-settings', element: <DefinitionSettings /> },
  {
    key: '/definition-settings/parameters/:integrationId',
    path: '/definition-settings/parameters/:integrationId',
    element: <IntegrationParameters />,
  }
];

export { privateRoutes, publicRoutes };
