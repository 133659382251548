import { useSelector } from 'react-redux';
import PageLayout from '../../../components/PageLayout';
import { RootState } from '../../../redux/common';
import { IntegrationState } from '../../../redux/reducers';
import IntegrationSection from './IntegrationSection';
import GeneratedApiKey from '../GeneratedApiKey';
import { useDisclosure } from '@mantine/hooks';

const SandboxPageNew = () => {
  const { activatedIntegrations } = useSelector<RootState, IntegrationState>((state: RootState) => {
    return state.integration;
  });

  const [
    generatedApiKeyModalOpened,
    { open: openGeneratedApiKeyModal, close: closeGeneratedApiKeyModal },
  ] = useDisclosure(false);

  return (
    <PageLayout id={'api-credentials-sandbox'}>
      <GeneratedApiKey
        id="generated-api-key-modal"
        opened={generatedApiKeyModalOpened}
        close={() => {
          closeGeneratedApiKeyModal();
        }}
      />
      {activatedIntegrations.map(({ integration }) => {
        return (
          <IntegrationSection
            integrationId={integration.id}
            key={integration.id}
            openGenerated={openGeneratedApiKeyModal}
          />
        );
      })}
    </PageLayout>
  );
};

export default SandboxPageNew;
