import { Navigate, Route, Routes } from 'react-router-dom';
import { privateRoutes, publicRoutes } from './routes';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/common';
import { AuthenticationState, TestFrameworkState } from '../redux/reducers';
import UAT from '../modules/UAT';

const RoutesHandler = () => {
  const { isAuthenticated } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });

  const { testSuiteDefinitions } = useSelector<RootState, TestFrameworkState>(
    (state: RootState) => {
      return state.testFramework;
    },
  );

  return (
    <Routes>
      {publicRoutes.map(({ key, path, element }) => {
        return <Route key={key} element={element} path={path} />;
      })}
      {privateRoutes.map(({ key, path, element }) => {
        return (
          <Route
            key={key}
            element={
              isAuthenticated ? (
                // TODO: This is a temporary until done dashboard page
                path === '/' ? (
                  <Navigate to={'/manage-api-access'} />
                ) : (
                  element
                )
              ) : (
                <Navigate to={'/login'} />
              )
            }
            path={path}
          />
        );
      })}
      {/* this is for handle UAT suits dynamically using integration definitions */}
      {testSuiteDefinitions.map(({ id }) => {
        return (
          <Route
            key={id}
            element={isAuthenticated ? <UAT /> : <Navigate to={'/login'} />}
            path={`/uat/${id}`}
          />
        );
      })}
      {testSuiteDefinitions.map(({ id }) => {
        return (
          <Route
            key={id}
            element={isAuthenticated ? <UAT /> : <Navigate to={'/login'} />}
            path={`/uat/${id}/:tabValue`}
          />
        );
      })}
    </Routes>
  );
};

export default RoutesHandler;
