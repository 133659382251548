import { MantineTheme, Sx } from '@mantine/core';

export const detailPageStyles = (theme: MantineTheme, isTelephoneError: boolean): Sx => {
  return {
    '.content': {
      background: '#fff',
      borderRadius: theme.radius.md,
      padding: theme.spacing.xl,

      '.header': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',

        '.title': {
          marginBottom: theme.spacing.xl,
        },
      },

      '.mantine-InputWrapper-root': {
        marginBottom: theme.spacing.md,
      },
      '.phone-container': {
        width: '100%',
        marginBottom: '1rem',
        '.mantine-Text-root': {
          span: {
            color: '#fa5252',
          },
        },
        '.phone-input': {
          width: '100%',
          border: isTelephoneError ? '0.0625rem solid #fa5252' : '0.0625rem solid #ced4da',
          '::placeholder': {
            color: isTelephoneError ? '#fa5252' : '#ADB5BD',
          },
        },
        '.flag-dropdown ': {
          border: isTelephoneError ? '0.0625rem solid #fa5252' : '0.0625rem solid #ced4da',
        },
        '.error-message': {
          textDecoration: 'none',
          wordBreak: 'break-word',
          color: '#fa5252',
          fontSize: 'calc(0.875rem - 0.125rem)',
          lineHeight: 1.2,
          display: 'block',
          marginTop: 'calc(0.625rem / 2)',
        },
      },
      '.country-phone-prefix': {
        width: '20%',
        marginRight: '30px',
      },
      '.country-number': {
        width: '80%',
      },
    },
  };
};
